import React from "react";
import Gradient1 from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import MenuNavigation from "../../components/MenuNavigation";
import "./PrivacyPolicy.css";
import { Helmet } from "react-helmet";

function PrivacyPolicy(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Privacy Policy</title>
        <link
          rel="canonical"
          href="https://www.alphaencoded.com/privacy-policy"
        />
      </Helmet>

      <div className="privacy-policy-screen screen">
        <Gradient1 />
        <MenuNavigation />
        <Geometrics />
        <div className="privacy-policy-container">
          <div className="privacy-policy-header spacegrotesk-bold-white-36px">
            Privacy Policy for Alpha Encoded Website
          </div>

          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            This Privacy Policy describes how Alpha Encoded collects, uses, and
            protects personal information obtained from users when accessing or
            using the Alpha Encoded website. By using the Website, you consent
            to the practices described in this Policy.
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Information We Collect:
            </h4>
            <p>
              1.1 Personal Information: We may collect personal information,
              such as your name, email address, phone number, and other
              information you voluntarily provide when you contact us or submit
              inquiries through the Website.
            </p>
            <p>
              1.2 Log Data: When you access the Website, we may automatically
              collect certain information, including your IP address, browser
              type, referring/exit pages, and operating system.
            </p>
            <p>
              1.3 Cookies: We may use cookies and similar tracking technologies
              to enhance your experience on the Website, improve our services,
              and analyze user behavior.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Use of Information:</h4>
            <p>2.1 We may use the personal information we collect to: </p>
            <ul>
              <li>-Respond to your inquiries and provide customer support.</li>
              <li>
                -Communicate with you about our services, updates, and
                promotional offers.
              </li>
              <li>
                -Customize and improve the content and functionality of the
                Website.
              </li>
              <li>
                -Monitor and analyze usage of the Website and its performance.
              </li>
            </ul>

            <p>
              2.2 We may combine personal information with information collected
              from other sources to provide a better user experience and improve
              our services.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Data Sharing and Disclosure:
            </h4>
            <p>
              3.1 We do not sell, trade, or rent your personal information to
              third parties without your consent, except as described in this
              Policy or required by law.
            </p>
            <p>
              3.2 We may share personal information with trusted third-party
              service providers who assist us in operating the Website and
              providing our services. These service providers are contractually
              obligated to protect the confidentiality and security of your
              personal information.
            </p>
            <p>
              3.3 We may disclose personal information if required to do so by
              law or in response to valid legal requests or to protect our
              rights, property, or safety or the rights, property, or safety of
              others.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Data Retention:</h4>
            <p>
              4.1 We retain personal information for as long as necessary to
              fulfill the purposes outlined in this Policy, unless a longer
              retention period is required or permitted by law.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Data Security:</h4>
            <p>
              5.1 We implement reasonable security measures to protect personal
              information from unauthorized access, use, or disclosure.
            </p>
            <p>
              5.2 Despite our efforts, no method of transmission over the
              internet or electronic storage is entirely secure. We cannot
              guarantee absolute security of your personal information.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Third-Party Links:</h4>
            <p>
              6.1 The Website may contain links to third-party websites. We are
              not responsible for the privacy practices or content of these
              websites. We encourage you to review the privacy policies of those
              third parties before providing any personal information.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Google Analytics:</h4>
            <p>
              7.1 We use Google Analytics, a web analytics service provided by
              Google Inc. ("Google"). Google Analytics collects data about your
              use of our website, such as your IP address, browser type,
              referring/exit pages, and interactions with our website. This data
              is used to improve our website's performance, understand user
              behavior, and make informed decisions about website content and
              design.<br></br> 7.2 Google Analytics operates independently of
              our website, and Google has its own privacy practices. You can
              review Google's Privacy Policy
              https://policies.google.com/privacy. To opt-out of being tracked
              by Google Analytics across all websites, you can use Google's
              browser add-on, available
              https://tools.google.com/dlpage/gaoptout. <br></br>7.3 By using
              our website, you consent to the collection and processing of data
              by Google Analytics for the purposes described above.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Children's Privacy:</h4>
            <p>
              7.1 The Website is not directed to individuals under the age of
              16. We do not knowingly collect personal information from
              children. If we become aware that we have inadvertently collected
              personal information from a child, we will take steps to delete
              it.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Changes to this Policy:
            </h4>
            <p>
              8.1 We may update this Policy from time to time. Any changes will
              be posted on this page, and the revised Policy will be effective
              when posted. We encourage you to review this Policy periodically
              for any updates.
            </p>
          </div>
          <div className="privacy-policy-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Contact Us:</h4>
            <p>
              9.1 If you have any questions, concerns, or requests please
              contact us at:{" "}
              <a
                href="mailto:info@alphanencoded.com"
                className="privacy-policy-email-link"
              >
                info@alphanencoded.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
