import { Canvas, useLoader } from "@react-three/fiber";
import {
  OrbitControls,
  Center,
  useGLTF,
  Environment,
  Preload,
} from "@react-three/drei";
import { useRef, Suspense } from "react";

export default function CanvasTest({ position = [-1, 0, 2.5], fov = 25 }) {
  return (
    <Canvas
      camera={{ position, fov }}
      eventPrefix="client"
      eventSource={document.getElementById("root")}
    >
      <Suspense fallback={null}>
        <ambientLight intensity={0.5}></ambientLight>
        <Environment files="./img/reflection.hdr" />
        <Center>
          <Test scale={[7, 7, 7]} />
        </Center>
        <Preload all />
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          autoRotate={true}
          autoRotateSpeed={4}
        />
      </Suspense>
    </Canvas>
  );
}

function Test(props) {
  const { nodes, materials } = useGLTF(
    "./models/compressed/iphoneModel-transformed.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]} scale={0.97}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <group position={[-2.23, 4.16, 0.64]}>
            <mesh
              geometry={nodes.camera_Mat2_0.geometry}
              material={materials["Mat.2"]}
            />
            <mesh
              geometry={nodes.camera_Mat4_0.geometry}
              material={materials["Mat.4"]}
            />
          </group>
          <group position={[-2.23, 5.95, 0.64]}>
            <mesh
              geometry={nodes.camera_1_Mat2_0.geometry}
              material={materials["Mat.2"]}
            />
            <mesh
              geometry={nodes.camera_1_Mat4_0.geometry}
              material={materials["Mat.4"]}
            />
          </group>
          <group position={[-0.56, 5.06, 0.64]}>
            <mesh
              geometry={nodes.camera_2_Mat2_0.geometry}
              material={materials["Mat.2"]}
            />
            <mesh
              geometry={nodes.camera_2_Mat4_0.geometry}
              material={materials["Mat.4"]}
            />
          </group>
          <group position={[-1.8, 1.78, -0.09]}>
            <mesh
              geometry={nodes.phone_Mat1_0.geometry}
              material={materials["Mat.1"]}
            />
            <mesh
              geometry={nodes.phone_Mat2_0.geometry}
              material={materials["Mat.2"]}
            />
            <mesh
              geometry={nodes.phone_Mat_0.geometry}
              material={materials.material}
            />
          </group>
          <mesh
            geometry={nodes.apple_logo_Mat2_0.geometry}
            material={materials["Mat.2"]}
            position={[0.22, 0.89, 0.39]}
            rotation={[-Math.PI / 2, -0.88, -Math.PI / 2]}
          />
          <mesh
            geometry={nodes.camera_3_Mat5_0.geometry}
            material={materials["Mat.5"]}
            position={[-0.56, 6.38, 0.64]}
          />
          <mesh
            geometry={nodes.camera_4_Mat4_0.geometry}
            material={materials["Mat.4"]}
            position={[-0.59, 3.78, 0.64]}
          />
          <mesh
            geometry={nodes.camera_5_Mat4_0.geometry}
            material={materials["Mat.4"]}
            position={[-0.01, 4.08, 0.64]}
          />
          <mesh
            geometry={nodes.camera_frame_Mat3_0.geometry}
            material={materials["Mat.3"]}
            position={[-1.39, 5.07, 0.59]}
          />
          <mesh
            geometry={nodes.volum__down__Mat2_0.geometry}
            material={materials["Mat.2"]}
          />
          <mesh
            geometry={nodes.volum_on_of_Mat2_0.geometry}
            material={materials["Mat.2"]}
          />
          <mesh
            geometry={nodes.volum_up__Mat2_0.geometry}
            material={materials["Mat.2"]}
            position={[3.57, 2.77, -0.02]}
          />
          <mesh
            geometry={nodes.volum_up__1_Mat2_0.geometry}
            material={materials["Mat.2"]}
            position={[-3.58, 2.08, -0.02]}
            rotation={[Math.PI, 0, Math.PI]}
          />
          <mesh
            geometry={nodes.volum_up__2_Mat2_0.geometry}
            material={materials["Mat.2"]}
            position={[-3.51, -2.04, -0.02]}
            rotation={[Math.PI, 0, Math.PI]}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("./models/compressed/iphoneModel-transformed.glb");
