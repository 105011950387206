import "./AnimatedText.css";
import { useState, useEffect } from "react";

function AnimatedText({ width }) {
  const textListWeb = [
    { word: "Cool", fontFamily: "Magical-Star", fontSize: 75 },
    { word: "Groovy", fontFamily: "Ice-Bold", fontSize: 52 },
    { word: "Nifty", fontFamily: "Monoton-Regular", fontSize: 50 },
    { word: "Rad", fontFamily: "Unkempt-Regular", fontSize: 52 },
    { word: "Fancy", fontFamily: "Spiced-Cheese", fontSize: 50 },
    { word: "Awesome", fontFamily: "Fancy-Signature", fontSize: 52 },
    { word: "Magical", fontFamily: "Awesome-Journey", fontSize: 52 },
  ];
  const textListMobile = [
    { word: "Cool", fontFamily: "Magical-Star", fontSize: 55 },
    { word: "Groovy", fontFamily: "Ice-Bold", fontSize: 32 },
    { word: "Nifty", fontFamily: "Monoton-Regular", fontSize: 28 },
    { word: "Rad", fontFamily: "Unkempt-Regular", fontSize: 32 },
    { word: "Fancy", fontFamily: "Spiced-Cheese", fontSize: 28 },
    { word: "Awesome", fontFamily: "Fancy-Signature", fontSize: 32 },
    { word: "Magical", fontFamily: "Awesome-Journey", fontSize: 32 },
  ];

  const [text, setText] = useState("");
  const [index, setIndex] = useState(1);
  const [textList, setTextList] = useState(textListWeb);
  const [textIndex, setTextIndex] = useState(0);
  const [fullText, setFullText] = useState("Great");
  const [isMobile, setIsMobile] = useState(false);

  const handleChange = () => {
    setIndex((index + 1) % textList.length);
    setText("");
    setFullText(textList[index].word);
    setTextIndex(0);
  };

  useEffect(() => {
    if (width <= 700) {
      setIsMobile(true);
      setTextList(textListMobile);
    } else {
      setIsMobile(false);
      setTextList(textListWeb);
    }
  }, [width]);

  useEffect(() => {
    const intervalId = setInterval(handleChange, 3000);
    return () => clearInterval(intervalId);
  }, [index]);

  useEffect(() => {
    if (textIndex < fullText.length) {
      setTimeout(() => {
        setText(text + fullText[textIndex]);
        setTextIndex(textIndex + 1);
      }, 80);
    }
  }, [textIndex]);

  const { fontFamily, fontSize } = textList[index];

  return (
    <h1 className="lets-build-cool-sht-together-1">
      Let's Build
      <span id="curlySpan">
        <span>{`{  `} </span>
        <p className="glitch axl3d ">
          <span className="test axl3d " style={{ fontFamily, fontSize }}>
            {text}
          </span>
          <span id="word-span" style={{ fontFamily, fontSize }}>
            {text}
          </span>
          <span className="test axl3d " style={{ fontFamily, fontSize }}>
            {text}
          </span>
        </p>
        <span>{`  }`}</span>
      </span>
      <br />
      Sh
      <span style={{ fontFamily: "var(--font-family-switzer-bold)" }}>*</span>t
      Together
    </h1>
  );
}

export default AnimatedText;
