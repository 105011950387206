import React from "react";
import App from "./App";
import "./globals.css";
import "./styleguide.css";
import { createRoot } from "react-dom/client";
import "intersection-observer";
IntersectionObserver.prototype.POLL_INTERVAL = 100;

const container = document.getElementById("app");
const root = createRoot(container);
root.render(<App />);
