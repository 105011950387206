import { useState } from "react";
import "./ReviewCard.css";

function ReviewCard(props) {
  const { ellipse164, name, alphaTeamHasBeen, className, company, openModal } =
    props;

  return (
    <>
      <div
        className={`frame-15-1-1 ${className || ""}`}
        onClick={() =>
          openModal({
            className: className,
            company: company,
            alphaTeamHasBeen: alphaTeamHasBeen,
            name: name,
            ellipse164: ellipse164,
          })
        }
      >
        <div className="frame-150-3">
          <img className="ellipse-164-5" src={ellipse164} alt="Ellipse 164" />
          <div className="frame-149-2">
            <div className="name spacegrotesk-bold-white-16px">{name}</div>
            <div className="ceo-bukalapak-2 spacegrotesk-normal-concord-14px">
              {company}
            </div>
          </div>
        </div>
        <p className="alpha-team-has-been-1 spacegrotesk-normal-silver-14px">
          {alphaTeamHasBeen}
        </p>
      </div>
    </>
  );
}

export default ReviewCard;
