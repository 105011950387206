import React from "react";
import "./ConServGeos.css";
import useWindowDimensions from "../../utils/WindowHook";
import { useState, useEffect } from "react";

function Geometrics() {
  const { height, width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();
  useEffect(() => {
    if (width <= 450) {
      setGeoLeft(width - 180);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 285);
    } else {
      setGeoLeft(width - 246);
    }
  }, [width]);
  return (
    <div>
      <div className="geomtris-top"></div>
      <div
        className="geo-bottom-conserv"
        style={{ left: `${geoLeft}px` }}
      ></div>
    </div>
  );
}

export default Geometrics;
