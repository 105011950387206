import React from "react";
import { Link } from "react-router-dom";
import WMC from "../../static/img/mwc.png";
import "./Footer-old.css";

function Footer(props) {
  const {
    vector221,
    bgWhite2,
    AlphaLogo,
    projects,
    services2,
    ourTeam2,
    home,
    place2,
    vector222,
    address,
    termsCondition,
    privacyPolicy,
  } = props;
  function RefreshLink() {
    const handleLinkClick = () => {
      window.location.reload();
    };
  }
  return (
    <div className="footer-1" id="footer">
      <div className="menu">
        <div className="logoDiv">
          <div className="logoContainer">
            <div className="logo-3"></div>
            <div className="wmc-div">
              <img src={WMC} className="wmc-image" />
            </div>
            <div className="wmc-div">
              <img
                src="https://content.pandia.com/badges/webdesign-badge1.png"
                className="wmc-image"
              />
            </div>
          </div>

          <div
            className="our-email spacegrotesk-normal-silver-20px"
            onClick={() => window.open("mailto:info@alphaencoded.com")}
          >
            {" "}
            info@alphaencoded.com
          </div>

          <a
            className="tel spacegrotesk-normal-silver-20px"
            href="tel: +1206-414-9693"
          >
            {" "}
            +1 206-414-9693
          </a>
        </div>
        <div className="footerList spacegrotesk-normal-white-20px">
          <Link to={"/"}>
            <div className="footerListItem">{home}</div>
          </Link>
          <Link to={"/services"}>
            <div className="footerListItem">{services2}</div>
          </Link>
          <Link to={"/about"}>
            <div className="footerListItem">About Us</div>
          </Link>
          <Link to={"/portfolio"}>
            <div className="footerListItem">Portfolio</div>
          </Link>
          <a href="/blog" className="no-decoration" onClick={RefreshLink}>
            <div className="footerListItem">Blog</div>
          </a>
          <Link to={"/contact-us"}>
            <div className="footerListItem">{place2}</div>
          </Link>
        </div>
      </div>

      <img className="vector-22-2" src={vector222} alt="Vector 22" />
      <div className="copyright spacegrotesk-normal-silver-20px">
        <div className="bottomDiv">
          <div className="addressDiv">
            <p className="address-1">{address}</p>
          </div>

          <div className="termsDiv">
            <Link to={"/term-and-conditions"} className="no-decoration">
              <div className="terms-condition-1">{termsCondition}</div>
            </Link>
            <Link to={"/privacy-policy"} className="no-decoration">
              <div className="privacy-policy">{privacyPolicy}</div>
            </Link>
          </div>
        </div>
        <div className="gradients">
          <div className="bottom-blur"></div>
          <div className="purple-bottom"></div>
          <div className="green-bottom"></div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
