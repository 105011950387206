import "./AnimatedTextGif.css";
import { useState, useEffect } from "react";
import animatedTextGif from "../../static/img/AnimatedTextNew.gif";

function AnimatedTextGif({ width }) {
  return (
    <div className="lets-build-cool-sht-together-1">
      Let's Build
      <span id="curlySpan">
        <img src={animatedTextGif} className="animatedTextGif" />
      </span>
      <br />
      Sh
      <span style={{ fontFamily: "var(--font-family-switzer-bold)" }}>*</span>t
      Together
    </div>
  );
}

export default AnimatedTextGif;
