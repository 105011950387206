import React from "react";
import Gradient1 from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import MenuNavigation from "../../components/MenuNavigation";
import "./NotFound.css";
import { Helmet } from "react-helmet";

function NotFound(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Not Found</title>
      </Helmet>

      <div className="not-found-screen">
        <Gradient1 />
        <MenuNavigation />
        <Geometrics />
        <div className="not-found-container">
          <h4 className="spacegrotesk-bold-white-36px">Page Not Found!</h4>
        </div>
      </div>
    </>
  );
}

export default NotFound;
