import React from "react";
import "./Frame2663.css";

function Frame2663() {
  return (
    <div className="frame-266-1">
      <img className="vuesaxoutlineadd-1" src="/img/vuesax-outline-add@2x.png" alt="vuesax/outline/add" />
      <div className="create-issue-1 inter-medium-white-14px">Create issue</div>
    </div>
  );
}

export default Frame2663;
