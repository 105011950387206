import React from "react";
import Gradient1 from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import MenuNavigation from "../../components/MenuNavigation";
import "./TermsAndConditions.css";
import { Helmet } from "react-helmet";

function TermsAndConditions(props) {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Terms and Conditions</title>
        <link
          rel="canonical"
          href="https://www.alphaencoded.com/term-and-conditions"
        />
      </Helmet>
      <div className="terms-and-conditions screen">
        <Gradient1 />
        <MenuNavigation />
        <Geometrics />
        <div className="terms-and-conditions-container">
          <div className="terms-main-header spacegrotesk-bold-white-36px">
            Terms and Conditions of Use for Alpha Encoded Website
          </div>

          <div className="terms-div spacegrotesk-normal-silver-20px">
            These terms and conditions govern the use of the Alpha Encoded
            website by the user. By accessing and using the Website, the User
            agrees to be bound by these terms and conditions.
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Use of the Website:</h4>
            <p>
              1.1 The User agrees to use the Website for lawful purposes only
              and in a manner consistent with all applicable laws and
              regulations.
            </p>
            <p>
              1.2 The User shall not engage in any activities that may disrupt
              or interfere with the proper functioning of the Website or
              compromise its security.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Intellectual Property:
            </h4>
            <p>
              2.1 All intellectual property rights related to the Website,
              including but not limited to text, graphics, logos, images, and
              software, are owned or licensed by the Alpha Encoded.
            </p>
            <p>
              2.2 The User agrees not to reproduce, modify, distribute, or use
              any materials from the Website without prior written permission
              from the Alpha Encoded.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">User Content:</h4>
            <p>
              3.1 The User may have the opportunity to submit or post content on
              the Website, such as comments or feedback.
            </p>
            <p>
              3.2 By submitting content, the User grants the Alpha Encoded a
              non-exclusive, royalty-free, worldwide license to use, reproduce,
              modify, and display the content for the purposes of operating and
              improving the Website.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Disclaimer of Liability:
            </h4>
            <p>
              4.1 The Alpha Encoded makes no representations or warranties
              regarding the accuracy, completeness, or reliability of the
              information or materials provided on the Website.
            </p>
            <p>
              4.2 The Alpha Encoded shall not be liable for any direct,
              indirect, incidental, consequential, or punitive damages arising
              from the User's use of the Website or reliance on any information
              or materials provided.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Links to Third-Party Websites:
            </h4>
            <p>
              5.1 The Website may contain links to third-party websites for the
              User's convenience and reference. The Alpha Encoded does not
              endorse or have control over the content or policies of these
              third-party websites.
            </p>
            <p>
              5.2 The Alpha Encoded shall not be responsible for any damages or
              losses incurred by the User in connection with the use of
              third-party websites.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Privacy Policy:</h4>
            <p>
              6.1 The Alpha Encoded collects, uses, and protects personal
              information in accordance with its Privacy Policy, which is
              available on the Website.
            </p>
            <p>
              6.2 By using the Website, the User consents to the collection,
              use, and processing of their personal information as described in
              the Privacy Policy.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Changes to the Agreement:
            </h4>
            <p>
              7.1 The Alpha Encoded reserves the right to modify or update these
              terms and conditions at any time without prior notice.
            </p>
            <p>
              7.2 It is the User's responsibility to review the Agreement
              periodically for any changes. Continued use of the Website after
              any modifications indicates the User's acceptance of the revised
              terms and conditions.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">
              Governing Law and Jurisdiction:
            </h4>
            <p>
              8.1 This Agreement shall be governed by and construed in
              accordance with the laws of the State of Washington.
            </p>
            <p>
              8.2 Any disputes arising out of or in connection with this
              Agreement shall be subject to the exclusive jurisdiction of the
              courts of the State of Washington.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <h4 className="switzer-medium-white-32px">Severability:</h4>
            <p>
              9.1 If any provision of this Agreement is found to be invalid,
              illegal, or unenforceable, the remaining provisions shall continue
              in full force and effect.
            </p>
          </div>
          <div className="terms-div spacegrotesk-normal-silver-20px">
            <p>
              By using the Website, the User acknowledges that they have read
              these Terms and Conditions.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
