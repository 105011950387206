import React from "react";
import { Link } from "react-router-dom";
import "./BottomJumbotron-orginal.css";
import useWindowDimensions from "../../utils/WindowHook";

function BottomJumbotron(props) {
  const { width } = useWindowDimensions();
  return (
    <div className="bottomJumbotron">
      <div className="gradient-container">
        <div className="left-gradient">
          <div className="purple"></div>
          <div className="green"></div>
        </div>
        <div className="right-gradient">
          <div className="purple2"></div>
          <div className="green2"></div>
        </div>
        <div className="geomtris-jumbo-right"></div>
        <div className="geomtris-left"></div>
      </div>
      <div className="marquee">
        <h2 className="mainHeading">READY TO BRING YOUR VISION TO LIFE? *</h2>
        <h2 className="mainHeading">READY TO BRING YOUR VISION TO LIFE? *</h2>
      </div>
      {width <= 450 ? (
        <div className="smallText spacegrotesk-normal-silver-20px">
          ✨ Let's make some digital magic! ✨
        </div>
      ) : (
        <div className="smallText spacegrotesk-normal-silver-20px">
          ✨ Get in touch with us today! Let's make some digital magic! ✨
        </div>
      )}

      <Link to={"/contact-us"}>
        <div className="button-1">
          <div className="view-more switzer-normal-eerie-black-16px">
            Let's Talk
          </div>
        </div>
      </Link>
    </div>
  );
}

export default BottomJumbotron;
