import React, { forwardRef } from "react";
import { onAppear } from "../AnimatedServiceText/AnimatedServiceText";
import "./AnimatedServiceTextHelper.css";

const MyComponent = forwardRef((props, ref) => {
  const { text } = props;

  return (
    <div
      className="sectionText spacegrotesk-normal-silver-20px bullet-text"
      ref={ref}
    >
      {text}
    </div>
  );
});
const EnhancedTextComponent = onAppear(MyComponent);
export default EnhancedTextComponent;
