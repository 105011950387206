const axios = require("axios").default;

// API Routes
export default {
  sendForm: async function (params) {
    const response = await axios
      .post(`/api`, {
        params,
      })
      .catch((error) => console.log(error));
    return response;
  },
};
