import React from "react";
import "./Frame217.css";

function Frame217(props) {
  const {
    salesManagement,
    image14,
    className,
    servicesProvided,
    servicesProvided2,
    servicesProvided3,
    description,
  } = props;

  return (
    <div className={`frame-21-2 ${className || ""}`}>
      <div className="frame-216">
        <div className="frame-208">
          <div className="frame-206">
            <div className="project-name spacegrotesk-normal-white-16px">
              Project Name
            </div>
            <div className="frame-212-1 web-development-4 switzer-regular-normal-white-20px">
              {salesManagement}
            </div>
          </div>
        </div>
        <div className="container-overlay">
          <img className="image-14-1" src={image14} alt="image 14" />
          <div class="overlay"></div>
          <div className="description">{description}</div>
        </div>
        <div className="frame-206">
          <div className="project-name spacegrotesk-normal-white-16px">
            Services
          </div>
          <div className="servicesContainer">
            {servicesProvided && (
              <div className="frame-212-1 web-development-4 ">
                {servicesProvided}
              </div>
            )}
            {servicesProvided2 && (
              <div className="frame-212-1 web-development-5 ">
                {servicesProvided2}
              </div>
            )}
            {servicesProvided3 && (
              <div className="frame-212-1 web-development-4 ">
                {servicesProvided3}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Frame217;
