import React from "react";
import "./Logo.css";
import AlphaLogo from "../../static/img/AlphaLogo.png";
import { Link } from "react-router-dom";

function MainLogo(props) {
  const { className } = props;

  return (
    <Link to={"/"}>
      <div className={`logo-5 ${className || ""}`}>
        <img className="bg-white-5" src={AlphaLogo} alt="Logo" />
      </div>
    </Link>
  );
}

export default MainLogo;
