import "./HomepageExperiment.css";
import { useState, useEffect } from "react";
import BottomJumbotron from "../../components/BottomJumbotron";
import MenuNavigation from "../../components/MenuNavigation";
import HoneyCombSection from "../../components/HoneyCombSection";
import JiraCardSection from "../../components/JiraCardSection";
import HeroSection from "../../components/HeroSection";
import TestimonialSection from "../../components/TestimonialSection";
import CardSection from "../../components/CardSection";
import useWindowDimensions from "../../utils/WindowHook";
import { Helmet } from "react-helmet";

function HomePageExperiment(props) {
  const {
    group298,
    line11,
    weAreADigitalAgency,
    fromDesignAndDeve,
    letsTalk,
    line12,
    whatWeOffer,
    line2,
    turningVisionsIntoReality,
    toDo1Issue,
    inputType,
    inputPlaceholder,
    ae1,
    vectorRight1,
    overlapGroup,
    number1,
    vectorRight2,
    inProgress,
    review,
    vectorRight3,
    overlapGroup2,
    number2,
    vectorRight4,
    done,
    line13,
    howWeCreate,
    weMadeAProcessForThat,
    takingTheTimeToT,
    line14,
    technologiesWeUse,
    stuffWeGeekOutAbout,
    weUtilizeTheLates,
    viewMore,
    line15,
    testimonials,
    whatOurLovelyClientsSay,
    x31Data,
    x32Data,
    x33Data,
    x34Data,
    x35Data,
    x36Data,
    webDevelopment1Data,
    webDevelopment2Data,
    webDevelopment3Data,
    webDevelopment4Data,
    group29531Props,
    group29532Props,
    frame1541Props2,
    frame1541Props,
    frame1542Props,
    frame1543Props,
    frame1544Props,
    frame1545Props,
  } = props;
  const [isMobile, setIsMobile] = useState(false);
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width <= 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Home</title>
        <link rel="canonical" href="https://www.alphaencoded.com/" />
        <meta
          name="description"
          content="Alpha Encoded is a Seattle-based digital agency specializing in web and mobile application development, UI/UX design, and digital marketing."
        />
      </Helmet>
      <div className="home-page-option-1 screen">
        <MenuNavigation />
        <HeroSection
          group298={group298}
          line11={line11}
          weAreADigitalAgency={weAreADigitalAgency}
          fromDesignAndDeve={fromDesignAndDeve}
          letsTalk={letsTalk}
        />
        <CardSection
          line12={line12}
          whatWeOffer={whatWeOffer}
          line2={line2}
          turningVisionsIntoReality={turningVisionsIntoReality}
          webDevelopment1Data={webDevelopment1Data}
          webDevelopment2Data={webDevelopment2Data}
          webDevelopment3Data={webDevelopment3Data}
          webDevelopment4Data={webDevelopment4Data}
        />
        <JiraCardSection
          toDo1Issue={toDo1Issue}
          inputType={inputType}
          inputPlaceholder={inputPlaceholder}
          ae1={ae1}
          vectorRight1={vectorRight1}
          overlapGroup={overlapGroup}
          number1={number1}
          vectorRight2={vectorRight2}
          inProgress={inProgress}
          review={review}
          vectorRight3={vectorRight3}
          overlapGroup2={overlapGroup2}
          number2={number2}
          vectorRight4={vectorRight4}
          done={done}
          line13={line13}
          howWeCreate={howWeCreate}
          weMadeAProcessForThat={weMadeAProcessForThat}
          takingTheTimeToT={takingTheTimeToT}
          x31Data={x31Data}
          x32Data={x32Data}
          x33Data={x33Data}
          x34Data={x34Data}
          x35Data={x35Data}
          x36Data={x36Data}
          group29531Props={group29531Props}
          group29532Props={group29532Props}
        />
        <HoneyCombSection
          line14={line14}
          technologiesWeUse={technologiesWeUse}
          stuffWeGeekOutAbout={stuffWeGeekOutAbout}
          weUtilizeTheLates={weUtilizeTheLates}
          viewMore={viewMore}
        />
        <TestimonialSection
          line15={line15}
          testimonials={testimonials}
          whatOurLovelyClientsSay={whatOurLovelyClientsSay}
          frame1541Props2={frame1541Props2}
          frame1541Props={frame1541Props}
          frame1542Props={frame1542Props}
          frame1543Props={frame1543Props}
          frame1544Props={frame1544Props}
          frame1545Props={frame1545Props}
        />
        <BottomJumbotron />
      </div>
    </>
  );
}

export default HomePageExperiment;
