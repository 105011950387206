import { useState } from "react";
import "./ReviewCardModal.css";

function ReviewCardModal(props) {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={props.onClose}>
          &times;
        </span>
        {props.children}
      </div>
    </div>
  );
}

export default ReviewCardModal;
