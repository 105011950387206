import "./CardSection.css";
import { useRef, useState, useEffect } from "react";
import CardWebDevelopmeent from "../../components/CardWebDevelopmeent";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";

function CardSection(props) {
  const {
    line12,
    whatWeOffer,
    line2,
    turningVisionsIntoReality,
    webDevelopment1Data,
    webDevelopment2Data,
    webDevelopment3Data,
    webDevelopment4Data,
  } = props;

  return (
    <motion.section className="section-services-1">
      <motion.div
        className="title-1 axl3d "
        initial={{ opacity: 0.1, y: 25 }}
        viewport={{ once: true }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{
          duration: 1.5,
          delay: 0.3,
          type: "tween",
        }}
      >
        <div className="chip-1">
          <img className="line" src={line12} alt="Line 1" />
          <div className="what-we-offer-1 spacegrotesk-bold-portage-16px">
            {whatWeOffer}
          </div>
          <img className="line" src={line2} alt="Line 2" />
        </div>
        <h2 className="turning-visions-into-reality-1 switzer-bold-white-52px">
          {turningVisionsIntoReality}
        </h2>
      </motion.div>

      <div className="services-1">
        <Link to={{ pathname: "/services", state: { from: "Web" } }}>
          <CardWebDevelopmeent
            cardHeader={webDevelopment1Data.webDevelopment}
            cardBody={webDevelopment1Data.weBuildWebsitesAn}
            src={webDevelopment1Data.imageSource}
            className={webDevelopment1Data.className}
            cardDelay={0.5}
          />
        </Link>
        <Link to={{ pathname: "/services", state: { from: "Mobile" } }}>
          <CardWebDevelopmeent
            cardHeader={webDevelopment2Data.webDevelopment}
            cardBody={webDevelopment2Data.weBuildWebsitesAn}
            src={webDevelopment2Data.imageSource}
            className={webDevelopment2Data.className}
            cardDelay={1}
          />
        </Link>
        <Link to={{ pathname: "/services", state: { from: "Marketing" } }}>
          <CardWebDevelopmeent
            cardHeader={webDevelopment3Data.webDevelopment}
            cardBody={webDevelopment3Data.weBuildWebsitesAn}
            src={webDevelopment3Data.imageSource}
            className={webDevelopment3Data.className}
            cardDelay={1.5}
          />
        </Link>
        <Link to={{ pathname: "/services", state: { from: "Design" } }}>
          <CardWebDevelopmeent
            cardHeader={webDevelopment4Data.webDevelopment}
            cardBody={webDevelopment4Data.weBuildWebsitesAn}
            src={webDevelopment4Data.imageSource}
            className={webDevelopment4Data.className}
            cardDelay={2}
          />
        </Link>
      </div>
    </motion.section>
  );
}
export default CardSection;
