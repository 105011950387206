import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
import "./HexagonGroupMobile.css";
import Hexagon from "../Hexagon";
import JavaScript from "../../static/img/tinified/JavaScript.png";
import GitHub from "../../static/img/tinified/GitHub.png";
import HTML from "../../static/img/tinified/html5.png";
import MongoDB from "../../static/img/tinified/MongoDB.png";
import NodeJS from "../../static/img/tinified/NodeJS.png";
import ReactJS from "../../static/img/tinified/ReactJS.png";
import MySQL from "../../static/img/tinified/MySQL.png";
import Kotlin from "../../static/img/tinified/Kotlin.png";
import Swift from "../../static/img/tinified/swiftIcon.png";
import AWS from "../../static/img/tinified/AWSIcon.png";
import Insomnia from "../../static/img/tinified/Insomnia.png";
import GraphQL from "../../static/img/tinified/graphql.png";
import Figma from "../../static/img/tinified/figma.png";
import ExpoGo from "../../static/img/tinified/expo.png";
import TopVector from "../../static/img/tinified/vectorTop.png";
import BottomVector from "../../static/img/tinified/BottomVector.png";
import {
  motion,
  AnimatePresence,
  LazyMotion,
  domAnimation,
  m,
} from "framer-motion";

const HexagonGroupMobile = () => {
  const hexagonGroupOne = [
    {
      icon: HTML,
      className: "normal",
      position: { top: "16px", left: "270px" },
    },
    {
      icon: MySQL,
      className: "normal",
      position: { top: "-50px", left: "270px" },
    },
    {
      icon: NodeJS,
      className: "normal",
      position: { top: "-268px", left: "360px" },
    },
    {
      icon: JavaScript,
      className: "normal",
      position: { top: "-440px", left: "180px" },
    },
    {
      icon: GitHub,
      className: "small",
      position: { top: "-565px", left: "105px" },
    },
    {
      icon: MongoDB,
      className: "normal",
      position: { top: "-675px", left: "180px" },
    },
    {
      icon: ReactJS,
      className: "normal",
      position: { top: "-1005px", left: "92px" },
    },
  ];
  const hexagonGroupTwo = [
    {
      icon: GraphQL,
      className: "normal",
      position: { top: "-35px", left: "210px" },
    },
    {
      icon: Insomnia,
      className: "normal",
      position: { top: "-40px", left: "125px" },
    },
    {
      icon: Kotlin,
      className: "small",
      position: { top: "-180px", left: "210px" },
    },
    {
      icon: ExpoGo,
      className: "normal",
      position: { top: "-440px", left: "210px" },
    },
    {
      icon: Swift,
      className: "normal",
      position: { top: "-555px", left: "300px" },
    },
    {
      icon: AWS,
      className: "normal",
      position: { top: "-780px", left: "390px" },
    },
    {
      icon: Figma,
      className: "normal",
      position: { top: "-1002px", left: "300px" },
    },
  ];
  const [refTop, inViewTop] = useInView();
  const [refBottom, inViewBottom] = useInView();
  const [currentGroup, setCurrentGroup] = useState(hexagonGroupTwo);
  const [groupIndex, setGroupIndex] = useState(0);

  useEffect(() => {
    if (inViewBottom === true) cycleGroups();
  }, [inViewBottom]);

  function cycleGroups() {
    if (groupIndex === 0) {
      setGroupIndex(1);
      setCurrentGroup(hexagonGroupOne);
    } else if (groupIndex === 1) {
      setGroupIndex(0);
      setCurrentGroup(hexagonGroupTwo);
    }
  }

  return (
    <div className="hexagonGroupContainer">
      <LazyMotion features={domAnimation}>
        <m.div className="vectorDivTop">
          <img
            className="vector-right-4"
            src={TopVector}
            alt="Vector - Right"
          />
        </m.div>
        <m.div className="vectorDivBottom" ref={refBottom}>
          <img
            className="vector-right-5"
            src={BottomVector}
            alt="Vector - Right"
          />
        </m.div>
        <AnimatePresence initial={false}>
          {currentGroup?.map((shape, index) => {
            return (
              <Hexagon
                className={shape.className}
                icon={shape.icon}
                position={shape.position}
                animate={shape.animate}
                key={index}
              />
            );
          })}
        </AnimatePresence>
      </LazyMotion>
    </div>
  );
};

export default HexagonGroupMobile;
