import React from "react";
import "./ConServGrad.css";

function TopGradient() {
  return (
    <div>
      <div className="home-top">
        <div className="purple-blur-top"></div>
        <div className="green-blur-top"></div>
      </div>
      <div className="gradient-blur">
        <div className="green-blur2"></div>
        <div className="purple-blur2"></div>
      </div>
      {/* <div className="left-blur">
        <div className="purple-blur"></div>
        <div className="green-blur"></div>
      </div> */}
    </div>
  );
}

export default TopGradient;
