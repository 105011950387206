import { useState, useEffect, useRef } from "react";
import MenuNavigation from "../../components/MenuNavigation";
import BottomJumbotron from "../../components/BottomJumbotron";
import "./Services.css";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import Gradients from "../../components/Gradient1";
import PenTool from "../../static/img/pen-tool.png";
import Monitor from "../../static/img/vuesax-twotone-monitor@2x.png";
import Mobile from "../../static/img/vuesax-outline-mobile@2x.png";
import Graph from "../../static/img/tinified/paint.png";
import ServiceSectionAnimatedDiv from "../../components/ServiceSectionAnimatedDiv";
import useWindowDimensions from "../../utils/WindowHook";
import { Helmet } from "react-helmet";

function Services(props) {
  const { line11, services, rectangle, text12 } = props;
  const [currentSection, setCurrentSection] = useState("Web");
  const designBtn = useRef(null);
  const webBtn = useRef(null);
  const marketingBtn = useRef(null);
  const mobileBtn = useRef(null);
  const location = useLocation();
  const { height, width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();
  useEffect(() => {
    if (width <= 450) {
      setGeoLeft(width - 180);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 285);
    } else {
      setGeoLeft(width - 346);
    }
  }, [width]);
  useEffect(() => {
    clearSections();
    if (currentSection === "Web") webBtn.current.classList.add("pressed");
    if (currentSection === "Mobile") mobileBtn.current.classList.add("pressed");
    if (currentSection === "Design") designBtn.current.classList.add("pressed");
    if (currentSection === "Marketing")
      marketingBtn.current.classList.add("pressed");
  }, [currentSection]);

  function clearSections() {
    webBtn.current.classList.remove("pressed");
    mobileBtn.current.classList.remove("pressed");
    designBtn.current.classList.remove("pressed");
    marketingBtn.current.classList.remove("pressed");
  }

  useEffect(() => {
    if (location.state !== undefined) {
      const { from } = location.state;
      setCurrentSection(from);
    }
  }, [location]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Services</title>
        <link rel="canonical" href="https://www.alphaencoded.com/services" />
        <meta
          name="description"
          content="Unlock the potential of your online presence with Alpha Encoded's exceptional services. Our skilled team specializes in web development, mobile app development, SEO optimization, and more. Enhance your online presence and achieve your business goals with our tailored solutions. Visit us now to explore how we can empower your digital journey."
        />
      </Helmet>
      <div className="services-3 screen">
        <div className="home-top">
          <div className="purple-blur-top"></div>
          <div className="green-blur-top"></div>
        </div>
        <div className="geomtris-top" style={{ left: `${geoLeft}px` }}></div>
        <MenuNavigation />

        <div className="hero-section-3">
          <div className="flex-col-services">
            <div className="flex-row">
              <motion.div
                className="healine"
                initial={{ x: -25, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 1, duration: 3 }}
                viewport={{ once: true }}
              >
                <div className="chip-5">
                  <img className="line-1-2" src={line11} alt="Line 1" />
                  <div className="services-4 spacegrotesk-bold-portage-16px">
                    {services}
                  </div>
                </div>
                <h1 className="we-prioritize-the-se switzer-bold-white-64px">
                  We build digital solutions that help you take your business to
                  the next level.
                </h1>
              </motion.div>
              <motion.div
                className="element-1"
                initial={{ x: 25, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ delay: 1, duration: 3 }}
                viewport={{ once: true }}
              >
                <div className="overlap-group1-3">
                  <div className="overlap-group-15">
                    <div className="ellipse-163-1"></div>
                    <img
                      className="rectangle-10"
                      src={rectangle}
                      alt="Rectangle"
                    />
                  </div>
                  <div className="text-12">{text12}</div>
                </div>
              </motion.div>
            </div>
            <div className="serviceSection">
              <div className="serviceButtons">
                <div
                  className="singleButton"
                  onClick={() => setCurrentSection("Web")}
                  ref={webBtn}
                >
                  <img src={Monitor} className="sectionIcon" />
                  <p className="switzer-regular-normal-white-20px service-btn-tag">
                    Web Development
                  </p>
                </div>
                <div
                  className="singleButton"
                  onClick={() => setCurrentSection("Mobile")}
                  ref={mobileBtn}
                  id="mobile"
                >
                  <img src={Mobile} className="sectionIcon" />
                  <p className="switzer-regular-normal-white-20px service-btn-tag">
                    Mobile Development
                  </p>
                </div>
                <div
                  className="singleButton"
                  onClick={() => setCurrentSection("Marketing")}
                  ref={marketingBtn}
                >
                  <img src={Graph} className="sectionIcon" />
                  <p className="switzer-regular-normal-white-20px service-btn-tag">
                    Branding
                  </p>
                </div>

                <div
                  className="singleButton"
                  onClick={() => setCurrentSection("Design")}
                  ref={designBtn}
                >
                  <img src={PenTool} className="sectionIcon" />
                  <p className="switzer-regular-normal-white-20px service-btn-tag">
                    UI/UX Design
                  </p>
                </div>
              </div>
              <div className="serviceSelected">
                <ServiceSectionAnimatedDiv
                  currentSection={currentSection}
                  width={width}
                />
              </div>
            </div>
          </div>
        </div>

        <BottomJumbotron />
      </div>
    </>
  );
}

export default Services;
