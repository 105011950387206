import React from "react";
import MenuNavigation from "../../components/MenuNavigation";
import BottomJumbotron from "../../components/BottomJumbotron";
import Frame221 from "../../components/Frame221";
import "./Portfolio.css";
import { motion } from "framer-motion";
import Gradients from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import { Helmet } from "react-helmet";

function Projects(props) {
  const {
    line12,
    seeProjectsThatWe,
    vector221,
    vector23,
    frame2211Props,
    frame2212Props,
    frame2213Props,
  } = props;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Portfolio</title>
        <link rel="canonical" href="https://www.alphaencoded.com/portfolio" />
      </Helmet>
      <div className="services-3 screen">
        <Gradients />
        <Geometrics />
        <MenuNavigation />
        <div className="section-our-projects">
          <div className="project">
            <motion.div
              className="title-21"
              initial={{ x: -25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 1, duration: 1.5 }}
              viewport={{ once: true }}
            >
              <div className="chip-6">
                <img className="line-1-2" src={line12} alt="Line 1" />
                <div className="projects-2 spacegrotesk-bold-portage-16px">
                  PORTFOLIO
                </div>
              </div>
              <h1 className="see-projects-that-we switzer-bold-white-52px">
                {seeProjectsThatWe}
              </h1>
            </motion.div>
            <div className="frame-224">
              <Frame221
                frame2171Props={frame2211Props.frame2171Props}
                frame2172Props={frame2211Props.frame2172Props}
              />
              <img className="vector-22-7" src={vector221} alt="Vector 22" />
              <Frame221
                frame2171Props={frame2212Props.frame2171Props}
                frame2172Props={frame2212Props.frame2172Props}
              />
              <img className="vector-23" src={vector23} alt="Vector 23" />
              <Frame221
                frame2171Props={frame2213Props.frame2171Props}
                frame2172Props={frame2213Props.frame2172Props}
              />
            </div>
          </div>
        </div>
        <BottomJumbotron />
      </div>
    </>
  );
}

export default Projects;
