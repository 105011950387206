import React from "react";
import { useState, useEffect } from "react";
import MenuNavigation from "../../components/MenuNavigation";
import BottomJumbotron from "../../components/BottomJumbotron";
import Header from "../../static/img/image.png";
import useWindowDimensions from "../../utils/WindowHook";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import "./AboutPage.css";

function AboutPage(props) {
  const { line1, aboutUs, line2, image, ourTeam } = props;
  const { height, width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();
  useEffect(() => {
    if (width <= 500) {
      setGeoLeft(width - 140);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 150);
    } else {
      setGeoLeft(width - 346);
    }
  }, [width]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | About Us</title>
        <link rel="canonical" href="https://www.alphaencoded.com/about" />
        <meta
          name="description"
          content="Get to know our talented team of digital enthusiasts, their passion for cutting-edge solutions, and our mission to redefine the digital landscape. Explore our About page to learn more about our journey and how we can shape yours."
        />
      </Helmet>
      <div className="about-page screen">
        <div className="geo-about-top" style={{ left: `${geoLeft}px` }}></div>
        <div className="geo-about-left"></div>
        <div className="geo-about-right" style={{ left: `${geoLeft}px` }}></div>
        <div className="geo-about-bottom"></div>
        <div className="top-grad">
          <div className="green-about"></div>
          <div className="purple-about"></div>
        </div>
        <div className="about-left">
          <div className="about-green"></div>
          <div className="about-purple"></div>
        </div>
        <div className="about-right">
          <div className="about-green2"></div>
          <div className="about-purple2"></div>
        </div>

        <MenuNavigation />
        <div className="hero-section-2">
          <div className="flex-col-3">
            <motion.div
              className="about-us"
              initial={{ y: 25, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
              viewport={{ once: true }}
            >
              <div className="chip-3">
                <img className="line-2" src={line1} alt="Line 1" />
                <div className="about-us-1 spacegrotesk-bold-portage-16px">
                  {aboutUs}
                </div>
                <img className="line-2" src={line2} alt="Line 2" />
              </div>
              <div className="frame-196">
                <img className="we-are-alpha-encoded" src={Header} />
              </div>
            </motion.div>
            <div
              className="image"
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          </div>
        </div>
        <div className="group-307">
          <div className="content-6">
            <motion.p
              className="we-are-a-digital-age-2"
              initial={{ y: 25, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
            >
              At Alpha Encoded, we're always at the forefront of new and
              exciting technology to ensure you receive the best solutions that
              meet your needs. With our team's various perspectives and our
              unwavering commitment to equity and inclusivity as a woman-owned
              business, we take care of you every step of the way. From custom
              software to websites, apps, and digital marketing, we have you
              covered. We'll bring your ideas to life, helping you establish a
              robust online presence that attracts visitors, generates leads,
              and empowers your brand to thrive.
            </motion.p>
          </div>
        </div>
        <div className="section-our-team">
          <motion.h2
            className="our-team-1 switzer-bold-white-64px "
            initial={{ y: 25, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 1.5 }}
            viewport={{ once: true }}
          >
            {ourTeam}
          </motion.h2>
          <div className="profile-team">
            <div className="profile-team-item">
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Lynna.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/lynna.jpg"
                  alt="image 14"
                />
              </div>

              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Alina.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Alina.jpg"
                  alt="image 14"
                />
              </div>
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Jessica.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Jessica.jpg"
                  alt="image 14"
                />
              </div>
            </div>
            <div className="names">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Lynna</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Founder & CEO
                </div>
              </div>

              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Alina</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Chief Operations Officer
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Jessica</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer
                </div>
              </div>
            </div>
            <div className="profile-team-item">
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Samantha.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Samantha.jpg"
                  alt="image 15"
                />
              </div>
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Angela.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Angela.jpg"
                  alt="image 15"
                />
              </div>
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/glitch-art-studio.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/IMG_2710.jpg"
                  alt="image 15"
                />
              </div>
            </div>
            <div className="names">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Samantha</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Angela</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer & QA
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Bagas</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  UI/UX Designer
                </div>
              </div>
            </div>
            <div className="profile-team-item">
              <div className="image-people">
                <img
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/TiffanyGif.gif"
                  className="gif"
                  alt="gif"
                />
                <img
                  className="image-14"
                  src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Tiffany.jpg"
                  alt="image 14"
                />
              </div>
            </div>
            <div className="names">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Tiffany</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Senior Visual and Graphic Designer
                </div>
              </div>
            </div>

            {/* This section is for mobile */}
            <div className="frame-2-2-1">
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Lynna.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/lynna.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Alina.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Alina.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
            </div>
            <div className="names-mobile">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Lynna</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Founder & CEO
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Alina</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Chief Operations Officer
                </div>
              </div>
            </div>
            <div className="frame-2-2-1">
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Jessica.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Jessica.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Samantha.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Samantha.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
            </div>
            <div className="names-mobile">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Jessica</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Samantha</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer
                </div>
              </div>
            </div>
            <div className="frame-2-2-1">
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Angela.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Angela.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/glitch-art-studio.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/IMG_2710.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
            </div>
            <div className="names-mobile">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Angela</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Software Engineer <br /> & QA
                </div>
              </div>
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Bagas</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  UI/UX Designer
                </div>
              </div>
            </div>

            <div className="names-mobile"></div>
            <div className="frame-2-2-1">
              <div className="profile-team-item-mobile">
                <div className="image-people">
                  <img
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/TiffanyGif.gif"
                    className="gif"
                    alt="gif"
                  />
                  <img
                    className="image-14"
                    src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/Tiffany.jpg"
                    alt="image 15"
                  />
                </div>
              </div>
            </div>
            <div className="names-mobile">
              <div className="frame-24-1">
                <div className="name-3 switzer-normal-white-28px">Tiffany</div>
                <div className="ceo-and-founder spacegrotesk-normal-silver-20px">
                  Senior Visual and Graphic Designer
                </div>
              </div>
            </div>
          </div>
        </div>
        <BottomJumbotron />
      </div>
    </>
  );
}

export default AboutPage;
