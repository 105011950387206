import "./HoneyCombSection.css";
import { useRef, useState, useEffect } from "react";
import HexagonGroup from "../../components/HexagonGroup";
import HexagonGroupMobile from "../HexagonGroupMobile";
import { motion } from "framer-motion";
import useWindowDimensions from "../../utils/WindowHook";
import ReviewCardModal from "../ReviewCardModal";
import Html from "../../static/img/Icons/Html.png";
import Css from "../../static/img/Icons/css.webp";
import Javascript from "../../static/img/Icons/javascript.png";
import Python from "../../static/img/Icons/python.png";
import Swift from "../../static/img/Icons/swift.webp";
import C from "../../static/img/Icons/c.png";
import Typescript from "../../static/img/Icons/Typescript.png";
import SwiftUI from "../../static/img/Icons/swiftui.png";
import Blender from "../../static/img/Icons/blender.png";
import Figma from "../../static/img/Icons/Figma.svg";
import Framer from "../../static/img/Icons/framer.png";
import MySQL from "../../static/img/Icons/mysql.svg";
import Mongoose from "../../static/img/Icons/mongoose.png";
import MongoDB from "../../static/img/Icons/mongodb.webp";
import AWS from "../../static/img/Icons/aws.png";
import Postgres from "../../static/img/Icons/postgres.svg";
import GraphQl from "../../static/img/Icons/Graphql.png";
import ReactJS from "../../static/img/Icons/reactjs.png";
import NodeJS from "../../static/img/Icons/nodejs.webp";
import Expressjs from "../../static/img/Icons/express.png";
import Angularjs from "../../static/img/Icons/angularjs.svg";
import AdobeXD from "../../static/img/Icons/adobe.png";
import Native from "../../static/img/Icons/reactnative.webp";
import Heroku from "../../static/img/Icons/heroku.webp";
import Expo from "../../static/img/Icons/expo.png";
import Firebase from "../../static/img/Icons/firebase.png";
import Shopify from "../../static/img/Icons/shopify.png";
import squarspace from "../../static/img/Icons/squarspace.png";
import VS from "../../static/img/Icons/VisualStudio.png";
import webflow from "../../static/img/Icons/webflow.png";
import wix from "../../static/img/Icons/wix.png";
import WordPress from "../../static/img/Icons/wordpress.png";
import Xcode from "../../static/img/Icons/xcode.png";
import Kotlin from "../../static/img/Icons/Kotlin.png";
import Insomnia from "../../static/img/Icons/insomnia.png";
function HoneyCombSection(props) {
  const {
    line14,
    technologiesWeUse,
    stuffWeGeekOutAbout,
    weUtilizeTheLates,
    viewMore,
    isSafari,
  } = props;
  const [isMobile, setIsMobile] = useState(false);
  const { height, width } = useWindowDimensions();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (width <= 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <div className="section-technologies-we-use">
      {modalIsOpen && (
        <ReviewCardModal onClose={closeModal}>
          <div className="modalContainer">
            <div className="line2"></div>
            <ul className="switzer-regular-normal-white-20px">
              LANGUAGES
              <div className="line2"></div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">HTML</li>
                <img src={Html} className="tech" alt="html" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">
                  JavaScript
                </li>
                <img src={Javascript} className="tech" alt="javascript" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Python</li>
                <img src={Python} className="tech" alt="python" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Swift</li>
                <img src={Swift} className="tech" alt="python" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">C#</li>
                <img src={C} className="tech" alt="python" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Kotlin</li>
                <img src={Kotlin} className="tech" alt="kotlin" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">
                  TypeScript
                </li>
                <img src={Typescript} className="last" alt="python" />
              </div>
            </ul>
            <div className="line2"></div>
            <ul className="switzer-regular-normal-white-20px">
              FRONTEND
              <div className="line2"></div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Blender</li>
                <img src={Blender} className="tech" alt="blender" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">CSS</li>
                <img src={Css} className="tech" alt="css" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">SwiftUI</li>
                <img src={SwiftUI} className="last" alt="swift" />
              </div>
            </ul>
            <div className="line2"></div>
            <ul className="switzer-regular-normal-white-20px">
              BACKEND
              <div className="line2"></div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">MySQL</li>
                <img src={MySQL} className="mysql" alt="mysql" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Mongoose</li>
                <img src={Mongoose} className="tech" alt="mongoose" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">MongoDB</li>
                <img src={MongoDB} className="tech" alt="mongodb" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">AWS</li>
                <img src={AWS} className="tech" alt="aws" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Postgres</li>
                <img src={Postgres} className="tech" alt="postgres" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Insomnia</li>
                <img src={Insomnia} className="tech" alt="insomnia" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">GraphQL</li>
                <img src={GraphQl} className="tech" alt="graphql" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Firebase</li>
                <img src={Firebase} className="last" alt="firebase" />
              </div>
            </ul>
            <div className="line2"></div>
            <ul className="switzer-regular-normal-white-20px">
              LIBRARIES
              <div className="line2"></div>
              <div className="Tech">
                <li className=" modal-row switzer-bold-white-12px">ReactJS</li>
                <img src={ReactJS} className="tech" alt="reactjs" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">NodeJS</li>
                <img src={NodeJS} className="tech" alt="nodejs" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">ExpressJS</li>
                <img src={Expressjs} className="tech" alt="express" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">AngularJS</li>
                <img src={Angularjs} className="tech" alt="angular" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">
                  React Native
                </li>
                <img src={Native} className="last" alt="native" />
              </div>
            </ul>
            <div className="line2"></div>
            <ul className="switzer-regular-normal-white-20px">
              TOOLS
              <div className="line2"></div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Figma</li>
                <img src={Figma} className="figma" alt="figma" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Framer</li>
                <img src={Framer} className="figma" alt="framer" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Heroku</li>
                <img src={Heroku} className="tech" alt="express" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">WebFlow</li>
                <img src={webflow} className="tech" alt="webflow" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">AdobeXD</li>
                <img src={AdobeXD} className="tech" alt="adobe" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Wix</li>
                <img src={wix} className="tech" alt="wix" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">WordPress</li>
                <img src={WordPress} className="tech" alt="word" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Shopify</li>
                <img src={Shopify} className="tech" alt="shopify" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">Expo</li>
                <img src={Expo} className="tech" alt="native" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">
                  Visual Studio Code
                </li>
                <img src={VS} className="tech" alt="vscode" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">XCode</li>
                <img src={Xcode} className="tech" alt="x" />
              </div>
              <div className="Tech">
                <li className="modal-row switzer-bold-white-12px">
                  SquareSpace
                </li>
                <img src={squarspace} className="tech" alt="vscode" />
              </div>
            </ul>
          </div>
        </ReviewCardModal>
      )}
      <motion.div
        className="technologies-we-use-1"
        initial={{ x: -25, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="technologiesHeader">
          <div className="headline-body">
            <div className="chip-2">
              <img className="line" src={line14} alt="Line 1" />
              <div className="technologies-we-use-2 spacegrotesk-bold-portage-16px">
                {technologiesWeUse}
              </div>
            </div>
            <h2 className="stuff-we-geek-out-about-1">{stuffWeGeekOutAbout}</h2>
            <p className="x-the-1 spacegrotesk-normal-silver-20px">
              {weUtilizeTheLates}
            </p>
          </div>

          <div onClick={openModal} className="button-1">
            <div className="view-more switzer-normal-eerie-black-16px">
              {viewMore}
            </div>
          </div>
        </div>
      </motion.div>
      <div className="hexagon-section">
        <div className="home-right2">
          <div className="home-purple3"></div>
          <div className="home-green3"></div>
        </div>
        {isMobile ? <HexagonGroupMobile /> : <HexagonGroup />}
      </div>
    </div>
  );
}
export default HoneyCombSection;
