import { useState } from "react";
import "./MenuNavigation.css";
import { Link, useLocation } from "react-router-dom";
import MainLogo from "../Logo";
import ContactPyramid from "../../utils/Pyramid";
import hamburgerMenu from "../../static/img/vuesax-outline-menu@2x.png";

function MenuNavigation() {
  const [active, setActive] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [isHovered, setIsHovered] = useState(false);

  function handleMenu() {
    setActive(!active);
    const navDropdown = document.querySelector(".navDropdown");
    if (active) {
      navDropdown.classList.add("inactive");
      navDropdown.classList.remove("active");
    } else {
      navDropdown.classList.add("active");
      navDropdown.classList.remove("inactive");
    }
  }
  function RefreshLink() {
    const handleLinkClick = () => {
      window.location.reload();
    };
  }

  return (
    <>
      <div className="navbar-1">
        <MainLogo />

        <div className="menu-navigation-1 spacegrotesk-medium-white-20px">
          <Link to={"/"} className="no-decoration">
            <div
              className={splitLocation[1] === "" ? "nav-active" : "product-1"}
            >
              Home
            </div>
          </Link>

          <Link to={"/services"} className="no-decoration">
            <div
              className={
                splitLocation[1] === "services" ? "nav-active" : "features-1"
              }
            >
              Services
            </div>
          </Link>

          {/* <div className="frame-50-1"> */}

          <Link to={"/about"} className="no-decoration">
            <div
              className={
                splitLocation[1] === "about" ? "nav-active" : "solutions-1"
              }
            >
              About Us
            </div>
          </Link>

          <Link to={"/portfolio"} className="no-decoration">
            <div
              className={
                splitLocation[1] === "portfolio" ? "nav-active" : "company-1"
              }
            >
              Portfolio
            </div>
          </Link>
          <a href="/blog" className="no-decoration" onClick={RefreshLink}>
            <div
              className={
                splitLocation[1] === "blog" ? "nav-active" : "company-1"
              }
            >
              Blog
            </div>
          </a>
        </div>
        <Link to={"/contact-us"}>
          <div
            className="buttonDiv"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <ContactPyramid hovered={isHovered} />

            <div
              className={isHovered ? "blurDiv active-blur" : "blurDiv "}
            ></div>
          </div>
        </Link>
        <div className="burgerMenuDiv">
          <img
            src={hamburgerMenu}
            onClick={() => handleMenu()}
            className="burgerMenu"
          />
        </div>
      </div>

      <div>
        <ul className="navDropdown">
          <Link to={"/"}>
            <li
              className={
                splitLocation[1] === ""
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              Home
            </li>
          </Link>

          <Link to={"/services"}>
            <li
              className={
                splitLocation[1] === "services"
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              Services
            </li>
          </Link>

          <Link to={"/about"}>
            <li
              className={
                splitLocation[1] === "about"
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              About Us
            </li>
          </Link>

          <Link to={"/portfolio"}>
            <li
              className={
                splitLocation[1] === "portfolio"
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              Portfolio
            </li>
          </Link>
          <a href="/blog" className="no-decoration" onClick={RefreshLink}>
            <li
              className={
                splitLocation[1] === "blog"
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              Blog
            </li>
          </a>

          <Link to={"/contact-us"}>
            <li
              className={
                splitLocation[1] === "contact-us"
                  ? "spacegrotesk-medium-white-20px nav-active"
                  : "spacegrotesk-medium-white-20px listItem"
              }
            >
              Contact Us
            </li>
          </Link>
        </ul>
      </div>
    </>
  );
}

export default MenuNavigation;
