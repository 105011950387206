import "./App.css";
import React, { useState, useEffect } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import HomePageExperiment from "./screens/HomepageExperiment";
import AboutPage from "./screens/AboutPage";
import ContactUs from "./screens/ContactUs";
import Services from "./screens/Services";
import Portfolio from "./screens/Portfolio";
import Blog from "./screens/Blog";
import Footer from "./components/Footer";
import CookieBar from "./components/CookiesBar";
import TermsAndConditions from "./screens/TermsAndConditions";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import NotFound from "./screens/NotFound";
import ScrollToTop from "./components/ScrollToTop";
import CookiesBar from "./components/CookiesBar";

function App() {
  // const [showBanner, setShowBanner] = useState(null);
  // useEffect(() => {
  //   if (alreadyCookies()) {
  //     setShowBanner(false);
  //   } else {
  //     setShowBanner(true);
  //   }
  // }, []);
  // function acceptCookies() {
  //   document.cookie = "gaAlphaConsent=accepted; max-age=31536000";
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "https://www.googletagmanager.com/gtag/js?id=G-REL1LYDY97";
  //   script.onload = () => {
  //     window.dataLayer = window.dataLayer || [];
  //     function gtag() {
  //       window.dataLayer.push(arguments);
  //     }
  //     gtag("js", new Date());
  //     gtag("config", "G-REL1LYDY97", {
  //       anonymize_ip: true,
  //     });
  //   };
  //   document.head.appendChild(script);
  //   setShowBanner(false);
  // }
  // function alreadyCookies() {
  //   const cookies = document.cookie.split(";");
  //   for (const cookie of cookies) {
  //     const [name, value] = cookie.trim().split("=");
  //     if (
  //       (name === "gaAlphaConsent" && value === "accepted") ||
  //       (name === "gaAlphaConsent" && value === "declined")
  //     ) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  // function declineCookies() {
  //   document.cookie = "gaAlphaConsent=declined; max-age=31536000";
  //   setShowBanner(false);
  // }
  return (
    <div className="mainContainer">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/:path(|home-page-option-1)">
            <HomePageExperiment {...homePageOption1Data} />
          </Route>
          <Route path="/about">
            <AboutPage {...aboutPageData} />
          </Route>
          <Route path="/contact-us">
            <ContactUs {...contactUsData} />
          </Route>
          <Route path="/services">
            <Services {...servicesData} />
          </Route>
          <Route path="/portfolio">
            <Portfolio {...projectsData} />
          </Route>
          <Route path="/blog">
            <Blog {...blogData} />
          </Route>
          <Route path="/term-and-conditions">
            <TermsAndConditions />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="*">
            <NotFound />
          </Route>
        </Switch>
        <Footer {...footerData} />
        {/* <CookiesBar
          visible={showBanner}
          acceptCookies={acceptCookies}
          declineCookies={declineCookies}
        />
        <CookieBar /> */}
      </Router>
    </div>
  );
}

export default App;

const chip1Data = {
  line1: "/img/line-1-5@2x.png",
  weAreADigitalAgency: "WE ARE A DiGITAL AGENCY",
};

const frame1111Data = {
  children: "Preview",
};

const frame1112Data = {
  children: "Headers",
  className: "frame-112",
};

const frame1113Data = {
  children: "Cookies",
  className: "frame-113",
};

const chip2Data = {
  line1: "/img/line-1@2x.png",
  weAreADigitalAgency: "WHAT WE OFFER",
};

const weAreSoftwareAgency1Data = {
  turningVisionsIntoReality: "Turning Visions Into Reality",
  chipProps: chip2Data,
};

const webDevelopment1Data = {
  className: "animate-enter3",
  imageSource: "/img/vuesax-twotone-monitor@2x.png",
  webDevelopment: "Web Development",
  weBuildWebsitesAn:
    "We build websites and web apps that are customized to fit a business's unique vibe.",
};

const webDevelopment2Data = {
  className: "card-mobile-app-development animate-enter4",
  imageSource: "/img/vuesax-outline-mobile@2x.png",
  webDevelopment: "Mobile Development",
  weBuildWebsitesAn:
    "Fast, feature-rich, and secure mobile apps on iOS and Android are our minimum standards.",
};

const webDevelopment3Data = {
  className: "card-marketing animate-enter5",
  imageSource: "/img/tinified/paint.png",
  webDevelopment: "Unique  \n Branding",
  weBuildWebsitesAn:
    "We help products and brands grow engaged audiences who become loyal customers.",
};

const webDevelopment4Data = {
  className: "card-design animate-enter6",
  imageSource: "/img/vuesax-outline-pen-tool.svg",
  webDevelopment: "UI/UX    \n          Design",
  weBuildWebsitesAn:
    "Designing experiences that humans love is what we do. We put the fun in functionality.",
};

const chip3Data = {
  line1: "/img/line-1@2x.png",
  weAreADigitalAgency: "HOW WE CREATE",
};

const frame2661Data = {
  vuesaxOutlineAdd: "/img/vuesax-outline-add-5@2x.png",
};

const group2951Data = {
  overlapGroup: "/img/rectangle-2@2x.png",
  number: "2",
};

const frame2662Data = {
  vuesaxOutlineAdd: "/img/vuesax-outline-add-4@2x.png",
};

const frame2663Data = {
  vuesaxOutlineAdd: "/img/vuesax-outline-add-6@2x.png",
};

const group2952Data = {
  overlapGroup: "/img/rectangle-4@2x.png",
  number: "3",
  className: "group-296",
};

const group2953Data = {
  overlapGroup: "/img/rectangle-5@2x.png",
  number: "4",
  className: "group-29",
};

const frame2664Data = {
  vuesaxOutlineAdd: "/img/vuesax-outline-add-7@2x.png",
};

const chip4Data = {
  line1: "/img/line-1-8@2x.png",
  weAreADigitalAgency: "TECHNOLOGIES WE USE",
};

const chip5Data = {
  line1: "/img/line-1-9@2x.png",
  weAreADigitalAgency: "TESTIMONIALS",
};

const weAreSoftwareAgency2Data = {
  turningVisionsIntoReality: (
    <React.Fragment>
      What Our Lovely <br />
      Clients Say
    </React.Fragment>
  ),
  className: "content-4",
  chipProps: chip5Data,
};

const frame1511Data = {
  ellipse164: "/img/ellipse-164-5@2x.png",
  alphaDidAmazingWo:
    "ALPHA did amazing work designing our webpage. They took our ideas to concept and turned it into really beautiful, artistic designs. Their style is unique, They’re quick to respond and really just fun to work with.",
};

const frame1512Data = {
  ellipse164: "/img/ellipse-164-7@2x.png",
  alphaDidAmazingWo:
    "ALPHA team are just amazing. We have had some personal websites and dashboards. The team always delivered on time. The best quality with endless iterations. Good Job!",
  className: "frame-293",
};

const headlineBody1Data = {
  webDevelopment: "Web Development",
  weBuildWebsitesAn:
    "We build websites and web apps that are customized to fit a business's unique vibe.",
  className: "headline-body-1",
};

const cardWebDevelopmeent1Data = {
  headlineBodyProps: headlineBody1Data,
};

const headlineBody2Data = {
  webDevelopment: "Mobile App Development",
  weBuildWebsitesAn:
    "Fast, feature-rich, and secure mobile apps on iOS and Android are our minimum standards.",
  className: "headline-body-1",
};

const cardWebDevelopmeent2Data = {
  className: "card-mobile-app-development",
  headlineBodyProps: headlineBody2Data,
};

const headlineBody3Data = {
  webDevelopment: (
    <React.Fragment>
      UI/UX <br />
      Design
    </React.Fragment>
  ),
  weBuildWebsitesAn:
    "Designing experiences that humans love is what we do. We put the fun in functionality.",
  className: "headline-body-1",
};

const cardWebDevelopmeent3Data = {
  className: "card-marketing",
  headlineBodyProps: headlineBody3Data,
  src: "/img/pen-tool.png",
};

const headlineBody4Data = {
  webDevelopment: "Digital Marketing",
  weBuildWebsitesAn:
    "We help products and brands grow engaged audiences who become loyal customers.",
  className: "headline-body-1",
};

const group29532Data = {
  number: "2",
};

const group29533Data = {
  number: "4",
  className: "group-29-2",
};
const x31Data = {
  body: "Let's chat about your ideas and define some goals.",
  mainHeader: "DISCOVERY",
  className: "",
};

const x32Data = {
  body: "We make the plan and you give us the green light.",
  mainHeader: "STRATEGY",
  className: "",
};

const x33Data = {
  body: " Then our designers make magic happen.",
  mainHeader: "DESIGN",
  className: "",
};

const x34Data = {
  body: "Now we make it real with coders + keyboards.",
  mainHeader: "DEVELOPMENT",
  className: "x3-2",
};

const x35Data = {
  body: "We test, squash bugs, and make sure nothing breaks.",
  mainHeader: "TESTING & QA",
  className: "x3-2",
};

const x36Data = {
  body: "We launch, celebrate, and support your growth.",
  mainHeader: "DELIVERY",
  className: "x5",
};

const frame1541Data = {
  ellipse164: "/img/testimonial2.png",
  name: "Melissa Damon",
  company: "Inglewood Physical Therapy",
  alphaTeamHasBeen:
    "My office hired Alpha Encoded to completely rebuild our outdated website and did a fantastic job! Lynna is very professional, knowledgeable, and easy to work with. As someone who doesn't have a lot of knowledge about website development, I appreciated that Lynna would take the time to explain how certain things work, provided us with recommendations, and overall made the process a smooth one. We are very happy with our new site! It is clear that Lynna knows what she is doing and I would absolutely recommend hiring her!",
};

const frame1541Data2 = {
  ellipse164: "/img/testimonial5.png",
  name: "Sara Sansbury",
  alphaTeamHasBeen:
    " I highly recommend Lynna and team for all your website needs! I had spent a lot of time creating my own website through squarespace and it was a huge challenge. My website was decent but wasn’t where I wanted it to be. So I finally decided, I needed an expert and I am so glad I chose Lynna. She is very knowledgeable and was able to address my concerns! She listened to my ideas and brought them to life! I am no opening another business and of course, I am having Lynna help me with my website again! She is so talented at what she does, that this time I will just give her creative freedom on my website. I can not recommend her enough! Thank you!",
};

const frame1542Data = {
  ellipse164: "/img/testimonial6.png",
  name: "Josh Simring",
  alphaTeamHasBeen:
    "Lynna and the team at Alpha Encoded are exactly what you look for in a company you want to work with. Not only are they attentive, but they're also genuinely good people who want to make a great product that both you and they are proud of. I highly recommend Lynna and her team at Alpha Encoded and I will be sure to use their services again in the future.",
  className: "frame-15-1-2",
  company: "Chessy",
};

const frame1543Data = {
  ellipse164: "/img/testimonial4.png",
  name: "Michael Hedgecock",
  alphaTeamHasBeen:
    "Lynna with Alpha Encoded has been a great help with the process of developing our App. The Alpha Encoded team has helped put together a strategic plan for us from design, development, and marketing. They even built and provided us with a nice landing page as work progresses. As someone with no knowledge of the tech and app industry, I have full confidence in working with them. Their transparency and explaining the steps and milestones has given us much knowledge with the application process. It's nice working with a company that shows honesty and integrity, and cares about designing quality work. Alpha will also be our go to company to maintain our app as well as using their marketing team and strategies. I value their knowledge and expertise.",
  className: "frame-15-1-3",
};

const frame1544Data = {
  ellipse164: "/img/testimonial3.png",
  name: "Gabriel Walton",
  alphaTeamHasBeen:
    "I’ve been working with Lynna and her team for over 9 months now and I am nothing but a raving fan for them! I had zero knowledge on app development and she has taken me from Kindergarten to now high school and has made the whole process so fun and has always been fully transparent with everything from day one. It has always been a one stop shop with her, from design, to development and marketing and various tips and tricks. She has so much knowledge and input with everything dealing with my app that it has truly made the process a stress free experience. She is always on point with keeping me in the loop with everything with weekly or bi weekly meetings and her willingness to always be available for any question pretty much at any hour of the day adds to the top notch quality experience. I would highly recommend Lynna and her team for any project small or big. As my own personal startup grows I will always plan on having them be a part of it and on my team.",
  className: "frame-15-1",
  company: "PLEY",
};

const frame1545Data = {
  ellipse164: "/img/testimonial1.jpeg",
  company: "L2Scrollio®",
  name: "Lori Saber",
  alphaTeamHasBeen:
    "As a small business owner intent on growing my on-line business, I hired Alpha Encoded to help me develop a new web platform to replace my existing environment that was not meeting business needs. Alpha Encoded was the perfect partner for me to work with, in retrospect. Lynna is professional, personable, and with a keen sense for when to lead and when to listen. She listened to my business requirements then provided technical solution options that were on-target for what I had envisioned. She then created my new website to support current functional needs as well as future expected growth. Much of the final work, which involved final visual formatting, was done as tandem client-coder development. This was not only a fun experience but helped establish a strong business partner relationship. I fully recommend Alpha Encoded for website development and will continue to leverage their skills in the future as my business changes over time.",
  className: "frame-15-1",
};
const blogData = {
  line1: "/img/line-1-11@2x.png",
  line2: "/img/line-2-1@2x.png",
};

const homePageOption1Data = {
  bgWhite1: "/img/bg-white@2x.png",
  alphaEncodedDc_Az_Rev3013121:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  place1: "Home",
  services1: "Services",
  ourTeam1: "Our Team",
  frame1: "/img/frame-5@2x.png",
  projects: "Projects",
  group298: "/img/InsomniaGroupImage.png",
  vector63: "/img/vector-63@2x.png",
  vector64: "/img/vector-64@2x.png",
  line11: "/img/line-1@2x.png",
  weAreADigitalAgency: "WE ARE A DIGITAL AGENCY",
  letsBuildCoolShTTogether: (
    <React.Fragment>Let’s Build Cool Sh*t Together</React.Fragment>
  ),
  fromDesignAndDeve:
    "From design and development of your website or mobile app, to marketing your project or brand, we’re the unicorn team to take you from start to scale.",
  letsTalk: "Let’s Talk",
  line12: "/img/line-1@2x.png",
  whatWeOffer: "WHAT WE OFFER",
  line2: "/img/line-2@2x.png",
  turningVisionsIntoReality: "Turning Visions Into Reality",
  speaker11: "/img/speaker--1--1@2x.png",
  toDo1Issue: "TO DO 1 ISSUE",
  inputType: "text",
  inputPlaceholder: "Start a project with Alpha Encoded team",
  ae1: "AE - 1",
  vectorRight1: "/img/vector---right@2x.png",
  overlapGroup: "/img/rectangle-14@2x.png",
  number1: "1",
  vectorRight2: "/img/vector---right@2x.png",
  inProgress: "IN PROGRESS",
  review: "REVIEW",
  vectorRight3: "/img/vector---right@2x.png",
  overlapGroup2: "/img/rectangle-14@2x.png",
  number2: "3",
  vectorRight4: "/img/vector---right@2x.png",
  done: "DONE",
  line13: "/img/line-1@2x.png",
  howWeCreate: "HOW WE CREATE",
  weMadeAProcessForThat: "We Made a Process For That!",
  takingTheTimeToT:
    "Taking the time to truly understand your vision and subsequent goals are critical in achieving them. At the center lie a passion for planning, unparalleled execution, and proactive communication.",
  spanText1: (
    <React.Fragment>
      DISCOVERY
      <br />
    </React.Fragment>
  ),
  spanText2: "",
  spanText3: "STRATEGY",
  spanText4: "",
  spanText5: "We make the plan and you give us the green light.",
  testingQaWeTest: (
    <React.Fragment>
      Testing &amp; QA
      <br />
      We test, squash bugs, and make sure nothing breaks.
    </React.Fragment>
  ),
  line14: "/img/line-1-3@2x.png",
  technologiesWeUse: "TECHNOLOGIES WE USE",
  stuffWeGeekOutAbout: "Stuff We Geek Out About",
  weUtilizeTheLates:
    "We utilize the latest technologies and industry best practices to ensure that our solutions are robust, reliable, and user-friendly.",
  viewMore: "View More",
  vectorRight5: "/img/vector---right-10@2x.png",
  vectorRight6: "/img/vector---right-11@2x.png",
  overlapGroup3: "/img/rectangle-58-7@2x.png",
  rectangle1: "/img/rectangle-17@2x.png",
  logoMysql170X1151: "/img/logo-mysql-170x115-1@2x.png",
  rectangle1691: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup1: "/img/rectangle-58-8@2x.png",
  rectangle2: "/img/rectangle-18@2x.png",
  html_5: "/img/html-5-1@2x.png",
  rectangle1692: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup22: "/img/rectangle-58-9@2x.png",
  rectangle3: "/img/rectangle-19@2x.png",
  frame2: "/img/frame-7@2x.png",
  rectangle1693: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  mongodb_Logomark_Springgreen1:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup32: "/img/rectangle-58-10@2x.png",
  rectangle4: "/img/rectangle-20@2x.png",
  mongodb_Logomark_Springgreen2: "/img/mongodb-logomark-springgreen-2@2x.png",
  rectangle1694: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup4: "/img/rectangle-58-11@2x.png",
  rectangle5: "/img/rectangle-21@2x.png",
  githubMark1: "/img/github-mark-1@2x.png",
  rectangle1695: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup5: "/img/rectangle-58-12@2x.png",
  rectangle6: "/img/rectangle-22@2x.png",
  science1: "/img/science-1@2x.png",
  rectangle1696: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  overlapGroup6: "/img/rectangle-58-13@2x.png",
  rectangle7: "/img/rectangle-10@2x.png",
  frame3: "/img/frame-6@2x.png",
  rectangle1697: "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  line15: "/img/line-1-3@2x.png",
  testimonials: "TESTIMONIALS",
  whatOurLovelyClientsSay: "What Our Lovely Clients Say",
  ellipse164: "/img/ellipse-164-5@2x.png",
  weAreConfidentTha1:
    "We are confident that our team can help your business achieve success through the development of high-quality software. Contact us today to learn more about how we can help you.",
  weAreConfidentTha2:
    "We are confident that our team can help your business achieve success through the development of high-quality software. Contact us today to learn more about how we can help you.",
  cta: "/img/cta.png",
  // vector221: "/img/vector-22.png",
  bgWhite2: "/img/bg-white-1@2x.png",
  alphaEncodedDc_Az_Rev3013122:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  // services2: "Services",
  // ourTeam2: "Our Team",
  // portfolio: "Portfolio",
  // place2: "Contact",
  // vector222: "/img/vector-22-1.png",
  // address: "2024 Alpha Encoded. All rights reserved",
  // termsCondition: "Terms & Condition",
  // privacyPolicy: "Privacy Policy",
  webDevelopment1Data,
  webDevelopment2Data,
  webDevelopment3Data,
  webDevelopment4Data,
  headlineBodyProps: headlineBody4Data,
  group29531Props: group29532Data,
  group29532Props: group29533Data,
  // x31Props: x31Data,
  // x32Props: x32Data,
  // x33Props: x33Data,
  x31Data,
  x32Data,
  x33Data,
  x34Data,
  x35Data,
  x36Data,
  frame1541Props2: frame1541Data2,
  frame1541Props: frame1541Data,
  frame1542Props: frame1542Data,
  frame1543Props: frame1543Data,
  frame1544Props: frame1544Data,
  frame1545Props: frame1545Data,
};
const footerData = {
  vector221: "/img/vector-22.png",
  bgWhite2: "/img/bg-white-1@2x.png",
  alphaEncodedDc_Az_Rev3013122:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  services2: "Services",
  ourTeam2: "Our Team",
  home: "Home",
  place2: "Contact",
  projects: "Projects",
  vector222: "/img/vector-22-1.png",
  address: "2024 Alpha Encoded. All rights reserved",
  termsCondition: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
};

const frame1241Data = {
  spanText1: "590",
  succesProject: "Succes Project",
};

const frame1242Data = {
  spanText1: "890",
  succesProject: "Happy Client",
};

const frame1243Data = {
  spanText1: "7",
  succesProject: "Years Of Experience",
};

const frame2461Data = {
  name: "Lynna Orm",
  ceoAndFounder: "CEO AND FOUNDER",
  className: "",
};

const frame2491Data = {
  image14: "../../static/img/lynna.png",
  frame246Props: frame2461Data,
};

const frame2462Data = {
  name: "Aratohrn",
  ceoAndFounder: "CREATIVE DIRECTOR",
  className: "frame-24",
};

const frame2492Data = {
  image14: "/img/image-15@2x.png",
  className: "frame-2-2-2",
  frame246Props: frame2462Data,
};

const frame2463Data = {
  name: "Armstrong",
  ceoAndFounder: "PROJECT MANAGEMENT",
  className: "",
};

const frame2493Data = {
  image14: "/img/image-16@2x.png",
  className: "frame-2-2-3",
  frame246Props: frame2463Data,
};

const frame2464Data = {
  name: "Alexavier",
  ceoAndFounder: "CEO AND FOUNDER",
  className: "",
};

const frame2494Data = {
  image14: "/img/image-15-1@2x.png",
  className: "frame-2-2-4",
  frame246Props: frame2464Data,
};

const imagePeople1Data = {
  src: "/img/IMG_8218.JPG",
  className: "",
};

const frame2465Data = {
  name: "Samantha Valle",
  ceoAndFounder: "DEVELOPER",
  className: "frame-24",
};

const frame2501Data = {
  imagePeopleProps: imagePeople1Data,
  frame246Props: frame2465Data,
};

const frame2466Data = {
  name: "Cassandra",
  ceoAndFounder: "PROJECT MANAGEMENT",
  className: "",
};

const frame2495Data = {
  image14: "/img/image-17-1@2x.png",
  className: "frame-2-2-5",
  frame246Props: frame2466Data,
};

const frame2467Data = {
  name: "Chelsea",
  ceoAndFounder: "CEO AND FOUNDER",
  className: "",
};

const frame2496Data = {
  image14: "/img/image-14-1@2x.png",
  className: "frame-2-2-6",
  frame246Props: frame2467Data,
};

const imagePeople2Data = {
  src: "/img/image-61@2x.png",
  className: "image-people-1",
};

const imagePeople3Data = {
  src: "/img/image-62@2x.png",
  className: "image-people-1",
};

const frame2468Data = {
  name: "Barnett",
  ceoAndFounder: "PROJECT MANAGEMENT",
  className: "",
};

const frame2502Data = {
  imagePeopleProps: imagePeople3Data,
  frame246Props: frame2468Data,
};

const frame2469Data = {
  name: "Caldwell",
  ceoAndFounder: "CEO AND FOUNDER",
  className: "",
};

const frame2497Data = {
  image14: "/img/image-15-2@2x.png",
  className: "frame-2-2-7",
  frame246Props: frame2469Data,
};

const frame24610Data = {
  name: "Cartwright",
  ceoAndFounder: "CREATIVE DIRECTOR",
  className: "frame-24",
};

const frame2498Data = {
  image14: "/img/image-15-3@2x.png",
  className: "frame-2-2",
  frame246Props: frame24610Data,
};

const imagePeople4Data = {
  src: "/img/image-63@2x.png",
  className: "image-people-1",
};

const frame24611Data = {
  name: "Charleston",
  ceoAndFounder: "PROJECT MANAGEMENT",
  className: "",
};

const frame2503Data = {
  imagePeopleProps: imagePeople4Data,
  frame246Props: frame24611Data,
};

const aboutPageData = {
  alphaEncodedDc_Az_Rev301312:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  bgWhite: "/img/bg-white-1@2x.png",
  line1: "/img/line-1-11@2x.png",
  aboutUs: "ABOUT US",
  line2: "/img/line-2-1@2x.png",
  weAreAlphaEncoded:
    " Alpha Encoded is a Minority Women-Owned Digital Agency based in Seattle, WA",
  weAreConfidentTha: "Redefining Industry Standards and Inspiring Change.",
  image: "/img/TeamPhoto.jpg",

  ourTeam: "Our Team",
  balduino: "Balduino",
  creativeDirector: "CREATIVE DIRECTOR",
  cta: "/img/frame-158.png",
  vector221: "/img/vector-22.png",
  powerfulForDevelop: "Powerful for developers. Fsat for everyone.",
  vector222: "/img/vector-22-1.png",
  frame1241Props: frame1241Data,
  frame1242Props: frame1242Data,
  frame1243Props: frame1243Data,
  frame2491Props: frame2491Data,
  frame2492Props: frame2492Data,
  frame2493Props: frame2493Data,
  frame2494Props: frame2494Data,
  frame2501Props: frame2501Data,
  frame2495Props: frame2495Data,
  frame2496Props: frame2496Data,
  imagePeopleProps: imagePeople2Data,
  frame2502Props: frame2502Data,
  frame2497Props: frame2497Data,
  frame2498Props: frame2498Data,
  frame2503Props: frame2503Data,
  frame246Props: frame2461Data,
  frame246Props: frame2462Data,
  frame246Props: frame2463Data,
  frame246Props: frame2464Data,
  frame246Props: frame2465Data,
  frame246Props: frame2466Data,
};

const inputField1Data = {
  label: "* First Name",
};

const inputField2Data = {
  label: "* What’s Your Company Name",
};

const inputField3Data = {
  label: "* Tell us your email",
  className: "input-field-1",
};

const inputField4Data = {
  label: "*Tell us about your project",
  className: "tell-us-about-your-project-1",
};

const frame2381Data = {
  children: "$10.000 - $20.000",
};

const frame2382Data = {
  children: "$20.000 - $30.000",
};

const logo2Data = {
  className: "logo-6",
};

const contactUsData = {
  alphaEncodedDc_Az_Rev301312:
    "/img/alpha-encoded-dc--az-rev3-01--3---1--2@2x.png",
  bgWhite: "/img/bg-white-1@2x.png",
  line1: "/img/line-1@2x.png",
  contactUs: "CONTACT US",
  letSTalkAboutYou:
    "Let's talk about your dream project, and start working with us.",
  typesOfWork: "*What can we help you with? *select all that apply",
  webDevelopment: " Web Development",
  mobileAppDevelopment: " Mobile App Development",
  marketing: " Marketing",
  design: " Design",
  sendRequest: "Send",
  vector221: "/img/vector-22.png",
  powerfulForDevelop: "Powerful for developers. Fsat for everyone.",
  vector222: "/img/vector-22-1.png",
  inputField1Props: inputField1Data,
  inputField2Props: inputField2Data,
  inputField3Props: inputField3Data,
  inputField4Props: inputField4Data,
  frame2381Props: frame2381Data,
  frame2382Props: frame2382Data,
  inputField1Props2: logo2Data,
};

const cardWebDevelopmeent22Data = {
  webDevelopment: "Web Development",
};

const cardWebDevelopmeent23Data = {
  webDevelopment: "Mobile Development",
  className: "card-mobile-app-development-1",
};

const cardMarketing1Data = {
  marketing: " Digital Marketing",
};

const cardMarketing2Data = {
  marketing: "UI/UX Design",
  className: "card-design-1",
};

const frame2171Data = {
  salesManagement: "GUARDIAN HOME INSPECTION SERVICES",
  image14: "/img/Guardian.png",
  className: "",
  servicesProvided: "Web Development",
  servicesProvided2: "Branding",
  servicesProvided3: "UI/UX Design",
  description:
    "Established a strong brand identity and built a user-friendly website for a Seattle-based home inspection business.",
};

const frame2172Data = {
  salesManagement: "CHESSY OTB CHESS ANALYSIS",
  image14: "/img/Chessy.png",
  className: "",
  servicesProvided: "Mobile Development",
  description:
    "iOS application that converts images of chess notations into digital PGN files using Google Vision API.",
};

const frame2211Data = {
  frame2171Props: frame2171Data,
  frame2172Props: frame2172Data,
};

const frame2173Data = {
  salesManagement: "DELIGHTFUL GROOMING",
  image14: "/img/Grooming.png",
  className: "frame-21-4",
  servicesProvided: "Web Development",
  servicesProvided3: "UI/UX Design",
  description:
    "Website for a Texas-based pet grooming service, enhancing their online customer engagement.",
};

const frame2174Data = {
  salesManagement: "PLEY (Coming Soon)",
  image14: "/img/Pley.jpg",
  className: "frame-21",
  servicesProvided: "Mobile Development",
  servicesProvided2: "Branding",
  servicesProvided3: "UI/UX Design",
  description:
    "Mobile app for beauty industry professionals, providing complete strategy, UI/UX design and custom development.",
};

const frame2212Data = {
  frame2171Props: frame2173Data,
  frame2172Props: frame2174Data,
};

const frame2175Data = {
  salesManagement: "DISCIPLINE",
  image14: "/img/Discipline.png",
  className: "",
  servicesProvided: "Web Development",
  servicesProvided2: "Branding",
  servicesProvided3: "UI/UX Design",
  description:
    "Revamped online presence by moving away from Wordpress, creating new branding and a modern website.",
};

const frame2176Data = {
  salesManagement: "THAT SPACE FOR MEN (Coming Soon)",
  image14: "/img/SpaceForMen.jpg",
  className: "",
  servicesProvided: "Mobile Development",
  servicesProvided2: "Branding",
  servicesProvided3: "UI/UX Design",
  description:
    "From concept to launch, developed a mental health app for men including strategy, UI/UX design, and web development.",
};

const frame2213Data = {
  frame2171Props: frame2175Data,
  frame2172Props: frame2176Data,
};

const logo3Data = {
  className: "logo-7",
};

const servicesData = {
  alphaEncodedDc_Az_Rev301312: "/img/Logo.png",
  bgWhite: "/img/bg-white-1@2x.png",
  line11: "/img/line-1@2x.png",
  services: "SERVICES",
  wePrioritizeTheSe: "We prioritize the services we offer to you.",
  rectangle: "/img/rectangle-24@2x.png",
  text12: "</>",
  number: "6",
  selectedProject: "Selected Project",
  vector66: "/img/vector-66@2x.png",

  cardWebDevelopmeent21Props: cardWebDevelopmeent22Data,
  cardWebDevelopmeent22Props: cardWebDevelopmeent23Data,
  cardMarketing1Props: cardMarketing1Data,
  cardMarketing2Props: cardMarketing2Data,
  frame2211Props: frame2211Data,
  frame2212Props: frame2212Data,
  frame2213Props: frame2213Data,
  logoProps: logo3Data,
};
const projectsData = {
  line12: "/img/line-1@2x.png",
  projects: "PROJECTS",
  seeProjectsThatWe: "Check out our latest projects.",
  vector221: "/img/vector-22-7.png",
  vector23: "/img/vector-22-7.png",
  cta: "/img/frame-158.png",
  vector222: "/img/vector-22.png",
  powerfulForDevelop: "Powerful for developers. Fast for everyone.",
  vector223: "/img/vector-22-1.png",

  cardMarketing1Props: cardMarketing1Data,
  cardMarketing2Props: cardMarketing2Data,
  frame2211Props: frame2211Data,
  frame2212Props: frame2212Data,
  frame2213Props: frame2213Data,
  logoProps: logo3Data,
};
