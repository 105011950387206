import { forwardRef, useEffect, useState, useRef } from "react";

// Loop only
const loopDelay = 1000;
const initDelay = 100;

// Do not configure
const letters = "abcdefghijklmnopqrstuvwxyz-.,+*!?@&%/=";
//The component
export const onAppear = (Component) => {
  return forwardRef((props, ref) => {
    const { text } = props;

    const [isVisible, setIsVisible] = useState(false);
    const [iteration, setIteration] = useState(0);
    const encrypt = (iteration) => {
      return text
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return text[index];
          }
          return letters[Math.floor(Math.random() * letters.length)];
        })
        .join("");
    };
    useEffect(() => {
      console.log(ref.current);
      if (ref.current) {
        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            console.log(entry);
            setIsVisible(entry.isIntersecting);
          });
        });

        observer.observe(ref.current);
        return () => observer.disconnect();
      }
    }, [ref]);

    useEffect(() => {
      let interval = null;
      if (isVisible) {
        interval = setTimeout(() => {
          setIteration((prev) => prev + 5 / 6);
          interval = setInterval(() => {
            setIteration((prev) => prev + 5 / 6);
          }, 100);
        }, 150);
      }
      return () => clearInterval(interval);
    }, [isVisible]);

    return (
      <Component
        ref={ref}
        {...props}
        text={isVisible ? encrypt(iteration, text) : text}
      />
    );
  });
};

export function onLoop(Component) {
  return (props) => {
    const value = props.children.props.children.props.children;

    const [isVisible, setIsVisible] = useState(false);
    const [iteration, setIteration] = useState(0);
    const [delayTime, setDelayTime] = useState(loopDelay);
    const intersectionRef = useRef(null);

    const encrypt = (iteration) => {
      const length = value.length;
      let result = "";

      for (let i = 0; i < length; i++) {
        const letter = value[i];

        if (i < iteration) {
          result += letter;
        } else {
          result += letters[Math.floor(Math.random() * 38)];
        }
      }

      if (iteration >= length) {
        setTimeout(() => {
          setIteration(0);
        }, delayTime);
      }

      return result;
    };

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      });

      observer.observe(intersectionRef.current);

      return () => observer.disconnect();
    }, []);

    useEffect(() => {
      let interval = null;

      if (isVisible) {
        interval = setTimeout(() => {
          setIteration((prev) => prev + 5 / 6);
          interval = setInterval(() => {
            setIteration((prev) => prev + 5 / 6);
          }, 50);
        }, initDelay);
      }

      return () => clearInterval(interval);
    }, [isVisible]);

    return (
      <Component
        ref={intersectionRef}
        {...props}
        text={isVisible ? encrypt(iteration) : value}
      />
    );
  };
}
