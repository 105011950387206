import React from "react";
import "./Group2953.css";

function Group2953(props) {
  const { number, className } = props;

  return (
    <div className={`group-29-2-1 ${className || ""}`}>
      <div className="group-297-2">
        <div className="overlap-group-14">
          <div className="number-7 inter-medium-white-24px">{number}</div>
        </div>
      </div>
    </div>
  );
}

export default Group2953;
