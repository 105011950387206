import "./X3.css";
import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import SVGIcon from "../SVGIcon";

function X3(props) {
  const { body, className, mainHeader, iconName } = props;

  const boxVariant = {
    visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
    hidden: { opacity: 0, scale: 0 },
  };

  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <>
      <motion.div
        className={`mainCard ${className || ""}`}
        variants={boxVariant}
        initial="hidden"
        ref={ref}
        animate={control}
      >
        <div className={`x3-1 x3-2 ${className || ""}`}>
          <SVGIcon iconName={iconName} />
          <p className="header-p">
            <span className="span0-1 spacegrotesk-bold-white-36px">
              {mainHeader}
            </span>

            <span className="design-then-our-desi-1 spacegrotesk-normal-white-22px">
              {body}
            </span>
          </p>
        </div>
      </motion.div>
    </>
  );
  w;
}

export default X3;
