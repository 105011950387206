import "./HeroSection.css";
import { useState, useEffect } from "react";
import AnimatedText from "../../components/AnimatedText";
import AnimatedTextGif from "../AnimatedTextGif";
import { motion } from "framer-motion";
import useWindowDimensions from "../../utils/WindowHook";
import InsomniaMobile from "../../static/img/insomniaGroupMobile.png";
import { Link } from "react-router-dom";

function HeroSection(props) {
  const { line11, weAreADigitalAgency, fromDesignAndDeve, letsTalk } = props;
  const { width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();
  const [isSafari, setIsSafari] = useState(false);
  const uA = navigator.userAgent;
  const vendor = navigator.vendor;
  useEffect(() => {
    if (
      /Safari/i.test(uA) &&
      /Apple Computer/.test(vendor) &&
      !/Mobi|Android/i.test(uA)
    ) {
      setIsSafari(true);
    }
  }, []);
  useEffect(() => {
    if (width <= 450) {
      setGeoLeft(width - 180);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 285);
    } else {
      setGeoLeft(width - 346);
    }
  }, [width]);
  return (
    <div className="hero-section-1">
      <div className="geo-top-home"></div>
      <div className="geo-home-right" style={{ left: `${geoLeft}px` }}></div>
      <div className="home-top">
        <div className="purple-blur-top"></div>
        <div className="green-blur-top"></div>
      </div>

      <motion.div
        className="heroWritingSection"
        initial={{ opacity: 0, x: -25 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        transition={{
          duration: 1.5,
          delay: 1,
          type: "tween",
        }}
      >
        <div className="content-5 ">
          <div className="we-are-software-agency-4">
            <div className="chip-1">
              <img className="line" src={line11} alt="Line 1" />
              <p className="we-are-a-digital-agency spacegrotesk-bold-portage-16px">
                {weAreADigitalAgency}
              </p>
            </div>
            {isSafari ? (
              <AnimatedTextGif width={width} />
            ) : (
              <AnimatedText width={width} />
            )}

            <p className="from-design-and-deve-1 spacegrotesk-normal-silver-20px">
              {fromDesignAndDeve}
            </p>
          </div>
          <Link to={"/contact-us"}>
            <div className="button-1">
              <div className="lets-talk-2 switzer-normal-eerie-black-16px">
                {letsTalk}
              </div>
            </div>
          </Link>
        </div>
      </motion.div>
      <div className="insomnia-group">
        <motion.div
          className="insomnia-group-photo"
          initial={{ opacity: 0, x: 25 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 1.5,

            delay: 1,
            type: "tween",
          }}
        >
          <img
            className="insomnia-image "
            src={
              width <= 700
                ? InsomniaMobile
                : "https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/InsomniaGroupImage2.png"
            }
            alt="Group 298"
          />
        </motion.div>
        <div className="home-right">
          <div className="home-purple"></div>
          <div className="home-green"></div>
        </div>
      </div>
    </div>
  );
}
export default HeroSection;
