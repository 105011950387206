import React from "react";
import MenuNavigation from "../../components/MenuNavigation";
import BottomJumbotron from "../../components/BottomJumbotron";
import Frame221 from "../../components/Frame221";
import "./Blog.css";
import { motion } from "framer-motion";
import Gradients from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import { Helmet } from "react-helmet";

function Blog(props) {
  const { line2, line1 } = props;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Blog</title>
        <link rel="canonical" href="https://www.alphaencoded.com/blog" />
      </Helmet>
      <script src="https://io.dropinblog.com/embedjs/1b6e162d-c4b5-4ae9-84aa-f7d5eb42011e.js"></script>
      <div className="blog-3 screen">
        <Gradients />
        <Geometrics />
        <MenuNavigation />
        <div className="section-our-blog">
          <div className="blog">
            <motion.div
              className="title-212"
              initial={{ x: -25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 1, duration: 1.5 }}
              viewport={{ once: true }}
            >
              <h1 className="see-blogs-that-we switzer-bold-white-52px">
                Blog
              </h1>
            </motion.div>
            <div className="blog-container">
              <div id="dib-posts"></div>
            </div>
          </div>
        </div>

        <BottomJumbotron />
      </div>
    </>
  );
}

export default Blog;
