import "./SVGIcon.css";
import { useState } from "react";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
IntersectionObserver.prototype.POLL_INTERVAL = 100;
export default function SVGIcon({ iconName }) {
  const [entered, setEntered] = useState(false);
  return (
    <InView
      as="div"
      onChange={(inView, entry) => {
        if (inView === true) {
          setEntered(true);
        }
      }}
      root={null}
      rootMargin="0px"
      threshold={0.8}
      className="innerIconDiv"
    >
      <div className="icon-container">
        <i className={`${iconName || ""} icon-test-one icon-size`}></i>
        <i className={`${iconName || ""} icon-size`}></i>
        <i className={`${iconName || ""} icon-test-two icon-size`}></i>
      </div>
    </InView>
  );
}
