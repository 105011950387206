import "./HeadlineBody.css";
import React from "react";

function HeadlineBody(props) {
  const { webDevelopment, weBuildWebsitesAn, className } = props;

  return (
    <div className={`headline-body-2 ${className || ""}`}>
      <h3 className="web-development-1 switzer-medium-white-32px">
        {webDevelopment}
      </h3>

      <p className="we-build-websites-an-1 spacegrotesk-normal-silver-20px">
        {weBuildWebsitesAn}
      </p>
    </div>
  );
}

export default HeadlineBody;
