import React from "react";
import "./Hexagon.css";
import { motion, m, LazyMotion, domAnimation } from "framer-motion";

const Hexagon = ({ icon, position, className }) => {
  return (
    <LazyMotion features={domAnimation}>
      <m.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          type: "spring",
          bounce: 0.3,
          duration: 1.5,
        }}
        className={`hexagon-container`}
        style={position}
      >
        <img
          src={icon}
          className={`hexagonImage ${className ? className : ""}`}
        />
      </m.div>
    </LazyMotion>
  );
};

export default Hexagon;
