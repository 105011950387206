import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import {
  OrbitControls,
  Center,
  useGLTF,
  Environment,
  AccumulativeShadows,
  RandomizedLight,
  GLTF,
  Preload,
} from "@react-three/drei";
import { useRef, Suspense, useState, useEffect } from "react";

export default function ContactPyramid({
  position = [-1, 0, 2.5],
  fov = 25,
  hovered,
}) {
  return (
    <Canvas
      camera={{ position, fov }}
      eventPrefix="client"
      eventSource={document.getElementById("root")}
    >
      <Suspense fallback={null}>
        <ambientLight intensity={0.5}></ambientLight>
        <Environment files="./img/reflection.hdr" />
        <Center>
          <Pyramid scale={[0.3, 0.3, 0.3]} hovered={hovered} />
        </Center>
        <Preload all />
      </Suspense>
    </Canvas>
  );
}
export function Pyramid(props) {
  const meshRef = useRef();

  const { nodes, materials } = useGLTF(
    "./models/compressed/Pyramid2-transformed.glb"
  );

  useFrame(() => (meshRef.current.rotation.y += 0.01));
  return (
    <group {...props} dispose={null} ref={meshRef}>
      <mesh geometry={nodes.Cone.geometry} rotation={[0, Math.PI / 4, 0]}>
        <meshStandardMaterial color={props.hovered ? "#13828667" : "#05403a"} />
      </mesh>
      <mesh
        geometry={nodes.Text.geometry}
        position={[1.06, -0.43, 0]}
        rotation={[1.58, 0.61, -1.59]}
      >
        <meshStandardMaterial
          color={props.hovered ? "#00d7c3" : materials.purplestuff.color}
        />
      </mesh>
      <mesh
        geometry={nodes.Text001.geometry}
        position={[-0.01, -0.41, 1.04]}
        rotation={[0.96, 0, 0.01]}
      >
        <meshStandardMaterial
          color={props.hovered ? "#00d7c3" : materials.purplestuff.color}
        />
      </mesh>
      <mesh
        geometry={nodes.Text002.geometry}
        position={[0, -0.41, -1.04]}
        rotation={[2.18, -0.02, 3.13]}
      >
        <meshStandardMaterial
          color={props.hovered ? "#00d7c3" : materials.purplestuff.color}
        />
      </mesh>
      <mesh
        geometry={nodes.Text003.geometry}
        position={[-1.02, -0.4, 0]}
        rotation={[1.57, -0.61, 1.56]}
      >
        <meshStandardMaterial
          color={props.hovered ? "#00d7c3" : materials.purplestuff.color}
        />
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/compressed/Pyramid2-transformed.glb");
