import "./CardWebDevelopmeent.css";
import React from "react";
import HeadlineBody from "../HeadlineBody";
import { motion, useAnimation } from "framer-motion";

function CardWebDevelopmeent(props) {
  const { cardHeader, cardBody, src, className, cardDelay } = props;

  return (
    <motion.div
      viewport={{ once: true }}
      initial={{ opacity: 0, y: -25 }}
      className={`card-web-developmeent ${className} axl3d `}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{
        duration: 2,
        delay: cardDelay,
        type: "tween",
      }}
    >
      <img className="icon-desktop-1" src={src} alt="icon-desktop" />
      <HeadlineBody webDevelopment={cardHeader} weBuildWebsitesAn={cardBody} />
    </motion.div>
  );
}

export default CardWebDevelopmeent;
