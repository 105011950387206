import { useRef, useState, useEffect } from "react";
import "./TestimonialSection.css";
import {
  motion,
  useScroll,
  useTransform,
  useDragControls,
} from "framer-motion";
import ReviewCard from "../../components/ReviewCard";
import useWindowDimensions from "../../utils/WindowHook";
import ReviewCardModal from "../ReviewCardModal";
import { useInView } from "react-intersection-observer";
// import Carousel from "../CarouselTestimonials";

function TestimonialSection(props) {
  const {
    line15,
    testimonials,
    whatOurLovelyClientsSay,
    frame1541Props2,
    frame1541Props,
    frame1542Props,
    frame1543Props,
    frame1544Props,
    frame1545Props,
  } = props;
  const [isMobile, setIsMobile] = useState(false);
  const { height, width } = useWindowDimensions();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  // const [testimonalRef, inView] = useInView();

  const openModal = (content) => {
    setModalIsOpen(true);
    setModalContent({ ...content });
  };
  const controls = useDragControls();

  function startDrag(event) {
    controls.start(event);
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent({});
  };

  useEffect(() => {
    if (width <= 810) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <div className="section-testimonial-1">
      {modalIsOpen && (
        <ReviewCardModal onClose={closeModal}>
          <div>
            <div className="frame-150-3 reviewModalHeading">
              <img
                className="ellipse-164-5"
                src={modalContent?.ellipse164}
                alt="Ellipse 164"
              />
              <div className="frame-149-2">
                <div className="name spacegrotesk-bold-white-16px">
                  {modalContent?.name}
                </div>
                <div className="ceo-bukalapak-2 spacegrotesk-normal-concord-14px">
                  {modalContent?.company}
                </div>
              </div>
            </div>
            <p className="alpha-team-has-been-1 spacegrotesk-normal-silver-14px">
              {modalContent?.alphaTeamHasBeen}
            </p>
          </div>
        </ReviewCardModal>
      )}
      <motion.div
        className="frame-147"
        initial={{ x: -25, opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
        viewport={{ once: true }}
      >
        <div className="testimonial">
          <div className="chip-22">
            <img className="line" src={line15} alt="Line 1" />
            <div className="testimonials-1 spacegrotesk-bold-portage-16px">
              {testimonials}
            </div>
          </div>
          <h2 className="what-our-lovely-clients-say-1 switzer-bold-white-52px">
            {whatOurLovelyClientsSay}
          </h2>
        </div>
      </motion.div>
      <div className="testimonial-1">
        <div className="overlap-group-13">
          {isMobile ? (
            <div className="leftScrollColumn">
              <div className="scrollGroup scroll-animation-mobile">
                <ReviewCard
                  ellipse164={frame1541Props.ellipse164}
                  name={frame1541Props.name}
                  alphaTeamHasBeen={frame1541Props.alphaTeamHasBeen}
                  company={frame1541Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1542Props.ellipse164}
                  name={frame1542Props.name}
                  alphaTeamHasBeen={frame1542Props.alphaTeamHasBeen}
                  className={frame1542Props.className}
                  company={frame1542Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1541Props2.ellipse164}
                  name={frame1541Props2.name}
                  alphaTeamHasBeen={frame1541Props2.alphaTeamHasBeen}
                  company={frame1541Props2.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1543Props.ellipse164}
                  name={frame1543Props.name}
                  alphaTeamHasBeen={frame1543Props.alphaTeamHasBeen}
                  className={frame1543Props.className}
                  company={frame1543Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1545Props.ellipse164}
                  name={frame1545Props.name}
                  alphaTeamHasBeen={frame1545Props.alphaTeamHasBeen}
                  className={frame1545Props.className}
                  company={frame1545Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1544Props.ellipse164}
                  name={frame1544Props.name}
                  alphaTeamHasBeen={frame1544Props.alphaTeamHasBeen}
                  className={frame1544Props.className}
                  company={frame1544Props.company}
                  openModal={openModal}
                />
              </div>

              <div className="scrollGroup scroll-animation-mobile">
                <ReviewCard
                  ellipse164={frame1541Props.ellipse164}
                  name={frame1541Props.name}
                  alphaTeamHasBeen={frame1541Props.alphaTeamHasBeen}
                  company={frame1541Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1542Props.ellipse164}
                  name={frame1542Props.name}
                  alphaTeamHasBeen={frame1542Props.alphaTeamHasBeen}
                  className={frame1542Props.className}
                  company={frame1542Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1541Props2.ellipse164}
                  name={frame1541Props2.name}
                  alphaTeamHasBeen={frame1541Props2.alphaTeamHasBeen}
                  company={frame1541Props2.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1543Props.ellipse164}
                  name={frame1543Props.name}
                  alphaTeamHasBeen={frame1543Props.alphaTeamHasBeen}
                  className={frame1543Props.className}
                  company={frame1543Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1545Props.ellipse164}
                  name={frame1545Props.name}
                  alphaTeamHasBeen={frame1545Props.alphaTeamHasBeen}
                  className={frame1545Props.className}
                  company={frame1545Props.company}
                  openModal={openModal}
                />
                <ReviewCard
                  ellipse164={frame1544Props.ellipse164}
                  name={frame1544Props.name}
                  alphaTeamHasBeen={frame1544Props.alphaTeamHasBeen}
                  className={frame1544Props.className}
                  company={frame1544Props.company}
                  openModal={openModal}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="leftScrollColumn">
                <div className="scrollGroup scroll-animation">
                  <ReviewCard
                    ellipse164={frame1541Props.ellipse164}
                    name={frame1541Props.name}
                    alphaTeamHasBeen={frame1541Props.alphaTeamHasBeen}
                    company={frame1541Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1542Props.ellipse164}
                    name={frame1542Props.name}
                    alphaTeamHasBeen={frame1542Props.alphaTeamHasBeen}
                    className={frame1542Props.className}
                    company={frame1542Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1541Props2.ellipse164}
                    name={frame1541Props2.name}
                    alphaTeamHasBeen={frame1541Props2.alphaTeamHasBeen}
                    company={frame1541Props2.company}
                    openModal={openModal}
                  />
                </div>

                <div className="scrollGroup scroll-animation">
                  <ReviewCard
                    ellipse164={frame1541Props.ellipse164}
                    name={frame1541Props.name}
                    alphaTeamHasBeen={frame1541Props.alphaTeamHasBeen}
                    company={frame1541Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1542Props.ellipse164}
                    name={frame1542Props.name}
                    alphaTeamHasBeen={frame1542Props.alphaTeamHasBeen}
                    className={frame1542Props.className}
                    company={frame1542Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1541Props2.ellipse164}
                    name={frame1541Props2.name}
                    alphaTeamHasBeen={frame1541Props2.alphaTeamHasBeen}
                    company={frame1541Props2.company}
                    openModal={openModal}
                  />
                </div>
              </div>
              <div className="rightScrollColumn">
                <div className="scrollGroup scroll-animation">
                  <ReviewCard
                    ellipse164={frame1543Props.ellipse164}
                    name={frame1543Props.name}
                    alphaTeamHasBeen={frame1543Props.alphaTeamHasBeen}
                    className={frame1543Props.className}
                    company={frame1543Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1545Props.ellipse164}
                    name={frame1545Props.name}
                    alphaTeamHasBeen={frame1545Props.alphaTeamHasBeen}
                    className={frame1545Props.className}
                    company={frame1545Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1544Props.ellipse164}
                    name={frame1544Props.name}
                    alphaTeamHasBeen={frame1544Props.alphaTeamHasBeen}
                    className={frame1544Props.className}
                    company={frame1544Props.company}
                    openModal={openModal}
                  />
                </div>
                <div className="scrollGroup scroll-animation">
                  <ReviewCard
                    ellipse164={frame1543Props.ellipse164}
                    name={frame1543Props.name}
                    alphaTeamHasBeen={frame1543Props.alphaTeamHasBeen}
                    className={frame1543Props.className}
                    company={frame1543Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1545Props.ellipse164}
                    name={frame1545Props.name}
                    alphaTeamHasBeen={frame1545Props.alphaTeamHasBeen}
                    className={frame1545Props.className}
                    company={frame1545Props.company}
                    openModal={openModal}
                  />
                  <ReviewCard
                    ellipse164={frame1544Props.ellipse164}
                    name={frame1544Props.name}
                    alphaTeamHasBeen={frame1544Props.alphaTeamHasBeen}
                    className={frame1544Props.className}
                    company={frame1544Props.company}
                    openModal={openModal}
                  />
                </div>
              </div>
            </>
          )}
          <div className="rectangle-173"></div>
          <div className="rectangle-175-1"></div>
        </div>
      </div>
    </div>
  );
}
export default TestimonialSection;
