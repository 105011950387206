import "./JiraCardSection.css";
import { useRef, useState, useEffect } from "react";
import VuesaxboldtickSquare from "../../components/VuesaxboldtickSquare";
import Frame2663 from "../../components/Frame2663";
import Group2953 from "../../components/Group2953";
import X3 from "../../components/X3";
import { motion, useScroll, useTransform, useAnimation } from "framer-motion";
import "../../screens/HomepageExperiment/HomepageExperiment.css";
import useWindowDimensions from "../../utils/WindowHook";
import jiraImage from "../../static/img/jiraImage.png";
import jira1 from "../../static/img/jira1.png";
import jira2 from "../../static/img/jira2.png";
import jira3 from "../../static/img/jira3.png";
import jira4 from "../../static/img/jira4.png";

function JiraCardSection(props) {
  const {
    toDo1Issue,
    inputType,
    inputPlaceholder,
    ae1,
    vectorRight1,
    overlapGroup,
    number1,
    vectorRight2,
    inProgress,
    review,
    vectorRight3,
    overlapGroup2,
    number2,
    vectorRight4,
    done,
    line13,
    howWeCreate,
    weMadeAProcessForThat,
    takingTheTimeToT,
    x31Data,
    x32Data,
    x33Data,
    x34Data,
    x35Data,
    x36Data,
    group29531Props,
    group29532Props,
  } = props;

  const { height, width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();
  useEffect(() => {
    if (width <= 450) {
      setGeoLeft(width - 250);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 370);
    } else if (width > 700 && width <= 1675) {
      setGeoLeft(width - 480);
    } else {
      setGeoLeft(width - 650);
    }
  }, [width]);

  const containerRef = useRef(null);
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
    offset: ["start end", "start start"],
  });

  const opacity = useTransform(scrollYProgress, [0, 0.5, 1], [0, 1, 1]);
  const x = useTransform(scrollYProgress, [0, 1], [30, 0]);
  const x2 = useTransform(scrollYProgress, [0, 1], [-30, 0]);

  return (
    <motion.section
      className="section-how-we-work-1"
      viewport={{ once: true }}
      id="jiraCardContainer"
    >
      <div className="visual-graphiic">
        <div className="home-left">
          <div className="home-green2"></div>
          <div className="home-purple2"></div>
        </div>
        <div
          className="geo-bottom-right"
          style={{ left: `${geoLeft}px` }}
        ></div>
        {/* <motion.div
          className="jiraImageDiv"
          viewport={{ once: true }}
          initial={{ opacity: 0, x: 25 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            duration: 1.5,
            delay: 0.8,
            type: "tween",
          }}
        >
          <img src={jiraImage} className="jiraImage" />
        </motion.div> */}

        <motion.div
          className="jiraImageDiv"
          viewport={{ once: true }}
          initial={{ opacity: 0, x: 25 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            duration: 1.5,
            delay: 0.8,
            type: "tween",
          }}
        >
          <img src={jira1} className="jiraImage" />
        </motion.div>
        <motion.div
          className="jiraImageDiv"
          viewport={{ once: true }}
          initial={{ opacity: 0, x: -25 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            duration: 1.5,
            delay: 1.3,
            type: "tween",
          }}
        >
          <img src={jira2} className="jiraImage" />
        </motion.div>
        <motion.div
          className="jiraImageDiv"
          viewport={{ once: true }}
          initial={{ opacity: 0, x: 25 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            duration: 1.5,

            delay: 1.8,
            type: "tween",
          }}
        >
          <img src={jira3} className="jiraImage" />
        </motion.div>
        <motion.div
          className="jiraImageDiv"
          viewport={{ once: true }}
          initial={{ opacity: 0, x: -25 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{
            duration: 1.5,

            delay: 1.8,
            type: "tween",
          }}
        >
          <img src={jira4} className="jiraImage" />
        </motion.div>
      </div>
      <div className="flex-col-1">
        <motion.div
          className="frame-135"
          initial={{ opacity: 0, y: -25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1.5,

            delay: 0.3,
            type: "tween",
          }}
          viewport={{ once: true }}
        >
          <div className="chip-2">
            <img className="line" src={line13} alt="Line 1" />
            <div className="how-we-create-1 spacegrotesk-bold-portage-16px">
              {howWeCreate}
            </div>
          </div>
          <h2 className="we-made-a-process-for-that-1 switzer-bold-white-52px">
            {weMadeAProcessForThat}
          </h2>
          <p className="x-the-1 spacegrotesk-normal-silver-20px">
            {takingTheTimeToT}
          </p>
        </motion.div>

        <motion.div
          style={{ x: x2, opacity }}
          ref={targetRef}
          className="jiraCardDiv"
          viewport={{ once: true }}
        >
          <X3 {...x31Data} iconName={"icon-logical-thinking"} />
          <X3 {...x32Data} iconName={"icon-roadmap"} />
          <X3 {...x33Data} iconName={"icon-ui"} />
          <X3 {...x34Data} iconName={"icon-web"} />
          <X3 {...x35Data} iconName={"icon-maintenance"} />
          <X3 {...x36Data} iconName={"icon-champagne"} />
        </motion.div>
      </div>
    </motion.section>
  );
}

export default JiraCardSection;
