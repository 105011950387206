import React from "react";
import { useState, useEffect } from "react";
import MenuNavigation from "../../components/MenuNavigation";
import { Link } from "react-router-dom";
import "./ContactUs.css";
import Gradient1 from "../../components/Gradient1";
import Geometrics from "../../components/Geometrics";
import API from "../../utils/api";
import { motion } from "framer-motion";
import useWindowDimensions from "../../utils/WindowHook";
import ReviewCardModal from "../../components/ReviewCardModal";
import { Helmet } from "react-helmet";

function ContactUs(props) {
  const {
    line1,
    contactUs,
    letSTalkAboutYou,
    webDevelopment,
    mobileAppDevelopment,
    marketing,
    design,
    sendRequest,
  } = props;

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [work, setWork] = useState([]);
  const [project, setProject] = useState("");
  const [active, setActive] = useState(false);
  const [mobileActive, setMobileActive] = useState(false);
  const [designActive, setDesignActive] = useState(false);
  const [marketingActive, setMarketingActive] = useState(false);
  const { height, width } = useWindowDimensions();
  const [geoLeft, setGeoLeft] = useState();

  useEffect(() => {
    if (width <= 450) {
      setGeoLeft(width - 180);
    } else if (width > 450 && width <= 700) {
      setGeoLeft(width - 285);
    } else {
      setGeoLeft(width - 346);
    }
  }, [width]);

  const handlemobileClick = (e) => {
    const service = e.target.value;
    setMobileActive(!mobileActive);

    setWork((currentWork) => {
      if (currentWork.includes(service)) {
        return currentWork.filter((item) => item !== service);
      } else {
        return [...currentWork, service];
      }
    });
  };

  const handleWebClick = (e) => {
    const service = e.target.value;
    setActive(!active);
    setWork((currentWork) => {
      if (currentWork.includes(service)) {
        return currentWork.filter((item) => item !== service);
      } else {
        return [...currentWork, service];
      }
    });
  };
  const handleMarketingClick = (e) => {
    const service = e.target.value;
    setMarketingActive(!marketingActive);
    setWork((currentWork) => {
      if (currentWork.includes(service)) {
        return currentWork.filter((item) => item !== service);
      } else {
        return [...currentWork, service];
      }
    });
  };
  const handleDesignClick = (e) => {
    const service = e.target.value;
    setDesignActive(!designActive);
    setWork((currentWork) => {
      if (currentWork.includes(service)) {
        return currentWork.filter((item) => item !== service);
      } else {
        return [...currentWork, service];
      }
    });
  };
  const goToModal = () => {
    window.scrollTo({
      top: 700,
      behavior: "smooth",
    });
  };
  const [modalIsOpen, setModalIsOpen] = useState(false);
  function reset() {
    setName("");
    setCompany("");
    setEmail("");
    setMarketingActive(false);
    setDesignActive(false);
    setActive(false);
    setMobileActive(false);
    setProject("");
  }
  useEffect(() => {
    if (modalIsOpen) {
      reset();
    }
  }, [modalIsOpen]);
  const handleSubmitForm = () => {
    API.sendForm({
      name: name,
      company: company,
      email: email,
      work: work,
      project: project,
    });
    goToModal();
    openModal();
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const validateForms = () => {
    if (name.length == 0) {
      alert("Name can not be empty!");
      return false;
    }
    if (company.length == 0) {
      alert("Company can not be empty!");
      return false;
    }
    if (email.length == 0) {
      alert("Email can not be empty!");
      return false;
    }
    if (project.length == 0) {
      alert("Project can not be empty!");
      return false;
    }
    return true;
  };
  const formSubmission = () => {
    const isValid = validateForms();
    if (isValid === true) {
      handleSubmitForm();
    }
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Alpha Encoded | Contact Us</title>
        <link rel="canonical" href="https://www.alphaencoded.com/contact-us" />
      </Helmet>
      <div className="contact-us-4 screen">
        <Gradient1 />
        <MenuNavigation />
        <Geometrics />
        <div className="hero-seccction">
          <div className="flex-col-4">
            <motion.div
              className="headline"
              initial={{ x: -25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 1, duration: 1.5 }}
              viewport={{ once: true }}
            >
              <div className="chip-4">
                <img className="line-1-1" src={line1} alt="Line 1" />
                <div className="contact-us-5 spacegrotesk-bold-portage-16px">
                  {contactUs}
                </div>
              </div>
              <h1 className="lets-talk-about-you switzer-bold-white-64px">
                {letSTalkAboutYou}
              </h1>
            </motion.div>
          </div>
        </div>
        <div className="section-form">
          <div className="form-contact-us">
            <motion.div
              className="data"
              initial={{ x: 25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
              viewport={{ once: true }}
            >
              <div className="content-8">
                <div className="labelName">
                  <div className="label-name switzer-bold-white-20px">
                    *Name
                  </div>
                  <div className="label-company switzer-bold-white-20px">
                    *Email
                  </div>
                </div>
                <div className="contactInfo">
                  <input
                    className=" enter-your-name  eudoxussans-medium-white-20px "
                    placeholder=""
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  ></input>
                  <input
                    className=" enter-your-email   eudoxussans-medium-white-20px "
                    placeholder=""
                    type="text"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  ></input>
                </div>
                <div className="label switzer-bold-white-20px">
                  *Company Name
                </div>
                <input
                  className="company-name-space   eudoxussans-medium-white-20px "
                  placeholder=""
                  type="text"
                  onChange={(e) => setCompany(e.target.value)}
                  value={company}
                ></input>
              </div>
            </motion.div>
            <motion.div
              className="types-of-work"
              initial={{ x: 25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
              viewport={{ once: true }}
            >
              {modalIsOpen ? (
                <ReviewCardModal className="modalSent" onClose={closeModal}>
                  <div className="modalSent">
                    <div className="modal-text switzer-regular-normal-white-20px">
                      Thank you for contacting us! We appreciate your message
                      and will be in touch with you as soon as possible. You can
                      also call or text us at +1 206-414-9693 from 9am - 5pm
                      PST.
                    </div>
                    <Link to={"/"}>
                      <div className="modal-button">Go Back To Home</div>
                    </Link>
                  </div>
                </ReviewCardModal>
              ) : (
                ""
              )}
              <div className="content-10">
                <p className="types-of-work-1 switzer-bold-white-20px">
                  *What can we help you with?{" "}
                  <small>*select all that apply</small>
                </p>
                <div className="service-choice">
                  <input
                    value={webDevelopment}
                    id="web"
                    type="button"
                    onClick={handleWebClick}
                    style={{
                      margin: active ? "-2px" : "0px",
                      boxShadow: active
                        ? " 0 0 10px 0 #00D7C3 inset, 0 0 20px 2px #00D7C3"
                        : "",
                      border: active ? "3px solid #00D7C3" : "",
                    }}
                    className="web-development-2 web-development-3 switzer-medium-white-20px"
                  />
                  <input
                    type="button"
                    className="mobile-app-development-2 switzer-medium-white-20px"
                    onClick={handlemobileClick}
                    value={mobileAppDevelopment}
                    style={{
                      margin: mobileActive ? "-2px" : "0px",
                      boxShadow: mobileActive
                        ? " 0 0 10px 0 #00D7C3 inset, 0 0 20px 2px #00D7C3"
                        : "",
                      border: mobileActive ? "3px solid #00D7C3" : "",
                    }}
                    id="mobile"
                  />
                  <input
                    className="service-choice-item marketing-1 switzer-medium-white-20px"
                    type="button"
                    onClick={handleMarketingClick}
                    style={{
                      margin: marketingActive ? "-2px" : "0px",
                      boxShadow: marketingActive
                        ? " 0 0 10px 0 #00D7C3 inset, 0 0 20px 2px #00D7C3"
                        : "",
                      border: marketingActive ? "3px solid #00D7C3" : "",
                    }}
                    value={marketing}
                    id="marketing"
                  />
                  <input
                    className="service-choice-item design-1 switzer-medium-white-20px"
                    type="button"
                    onClick={handleDesignClick}
                    style={{
                      margin: designActive ? "-2px" : "0px",
                      boxShadow: designActive
                        ? " 0 0 10px 0 #00D7C3 inset, 0 0 20px 2px #00D7C3"
                        : "",
                      border: designActive ? "3px solid #00D7C3" : "",
                    }}
                    value={design}
                    id="design"
                  />
                </div>
              </div>
            </motion.div>
            <motion.div
              className="tell-us-about-your-project"
              viewport={{ once: true }}
              initial={{ x: 25, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: 0.5, duration: 1.5 }}
            >
              <div className="tell-us-about-your-project-1">
                <div className="switzer-bold-white-20px">
                  *Tell us about your project
                </div>
                <textarea
                  className="tell-us-about-your-project-2   eudoxussans-medium-white-20px "
                  placeholder=""
                  type="text"
                  onChange={(e) => setProject(e.target.value)}
                  value={project}
                ></textarea>
              </div>
            </motion.div>
          </div>

          <motion.button
            className="button-6"
            onClick={formSubmission}
            initial={{ y: 25, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.5, duration: 1.5 }}
            viewport={{ once: true }}
          >
            <div className="send-request  switzer-normal-eerie-black-16px">
              {sendRequest}
            </div>
          </motion.button>
        </div>
      </div>
    </>
  );
}
export default ContactUs;
