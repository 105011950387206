import { useInView } from "react-intersection-observer";
import { useState, useEffect } from "react";
import "./HexagonGroup.css";
import Hexagon from "../Hexagon";
import JavaScript from "../../static/img/tinified/JavaScript.png";
import GitHub from "../../static/img/tinified/GitHub.png";
import HTML from "../../static/img/tinified/html5.png";
import MongoDB from "../../static/img/tinified/MongoDB.png";
import NodeJS from "../../static/img/tinified/NodeJS.png";
import ReactJS from "../../static/img/tinified/ReactJS.png";
import MySQL from "../../static/img/tinified/MySQL.png";
import Kotlin from "../../static/img/tinified/Kotlin.png";
import Swift from "../../static/img/tinified/swiftIcon.png";
import AWS from "../../static/img/tinified/AWSIcon.png";
import Insomnia from "../../static/img/tinified/Insomnia.png";
import GraphQL from "../../static/img/tinified/graphql.png";
import Figma from "../../static/img/tinified/figma.png";
import ExpoGo from "../../static/img/tinified/expo.png";
import TopVector from "../../static/img/tinified/vectorTop.png";
import BottomVector from "../../static/img/tinified/BottomVector.png";
import { motion, AnimatePresence } from "framer-motion";

const HexagonGroup = () => {
  const hexagonGroupOne = [
    {
      icon: HTML,
      animate: "first",
      position: { top: "43px", left: "270px" },
    },
    {
      icon: MySQL,
      animate: "second",
      position: { top: "35px", left: "270px" },
    },
    {
      icon: NodeJS,
      animate: "third",
      position: { top: "-215px", left: "415px" },
    },
    {
      icon: JavaScript,
      animate: "fourth",
      position: { top: "-390px", left: "125px" },
    },
    {
      icon: GitHub,
      animate: "fifth",
      position: { top: "-485px", left: "5px" },
    },
    {
      icon: MongoDB,
      animate: "sixth",
      position: { top: "-565px", left: "125px" },
    },
    {
      icon: ReactJS,
      animate: "seventh",
      position: { top: "-975px", left: "-20px" },
    },
  ];
  const hexagonGroupTwo = [
    {
      icon: GraphQL,
      animate: "third",
      position: { top: "-30px", left: "178px" },
    },
    {
      icon: Insomnia,
      animate: "fourth",
      position: { top: "60px", left: "50px" },
    },
    {
      icon: Kotlin,
      animate: "seventh",
      position: { top: "-70px", left: "190px" },
    },
    {
      icon: ExpoGo,
      animate: "second",
      position: { top: "-380px", left: "178px" },
    },
    {
      icon: Swift,
      animate: "seventh",
      position: { top: "-465px", left: "323px" },
    },
    {
      icon: AWS,
      animate: "seventh",
      position: { top: "-715px", left: "467px" },
    },
    {
      icon: Figma,
      animate: "first",
      position: { top: "-970px", left: "323px" },
    },
  ];
  const [refTop, inViewTop] = useInView();
  const [refBottom, inViewBottom] = useInView();
  const [currentGroup, setCurrentGroup] = useState(hexagonGroupTwo);
  const [groupIndex, setGroupIndex] = useState(0);

  useEffect(() => {
    if (inViewBottom === true) {
      const intervalId = setInterval(cycleGroups, 3000);
      return () => clearInterval(intervalId);
    }
  }, [groupIndex, inViewBottom]);

  function cycleGroups() {
    if (groupIndex === 0) {
      setGroupIndex(1);
      setCurrentGroup(hexagonGroupOne);
    } else if (groupIndex === 1) {
      setGroupIndex(0);
      setCurrentGroup(hexagonGroupTwo);
    }
  }

  return (
    <div className="hexagonGroupContainer">
      <motion.div
        ref={refTop}
        className={inViewTop ? "vectorDivTop animate-top" : "vectorDivTop"}
      >
        <img className="vector-right-4" src={TopVector} alt="Vector - Right" />
      </motion.div>
      <motion.div
        ref={refBottom}
        className={
          inViewTop ? "vectorDivBottom animate-bottom" : "vectorDivBottom"
        }
      >
        <img
          className="vector-right-5"
          src={BottomVector}
          alt="Vector - Right"
        />
      </motion.div>
      <AnimatePresence>
        {currentGroup?.map((shape, index) => {
          return (
            <Hexagon
              className={shape.className}
              icon={shape.icon}
              position={shape.position}
              animate={shape.animate}
              key={index}
            />
          );
        })}
      </AnimatePresence>
    </div>
  );
};

export default HexagonGroup;
