import React from "react";
import Frame217 from "../Frame217";
import "./Frame221.css";

function Frame221(props) {
  const { frame2171Props, frame2172Props, vector221 } = props;

  return (
    <div className="frame-22">
      <Frame217
        salesManagement={frame2171Props.salesManagement}
        image14={frame2171Props.image14}
        className={frame2171Props.className}
        description={frame2171Props.description}
        servicesProvided={frame2171Props.servicesProvided}
        servicesProvided2={frame2171Props.servicesProvided2}
        servicesProvided3={frame2171Props.servicesProvided3}
      />
      <div className="mobile-show"></div>

      <Frame217
        salesManagement={frame2172Props.salesManagement}
        image14={frame2172Props.image14}
        className={frame2172Props.className}
        description={frame2172Props.description}
        {...(frame2172Props.servicesProvided && {
          servicesProvided: frame2172Props.servicesProvided,
        })}
        {...(frame2172Props.servicesProvided2 && {
          servicesProvided2: frame2172Props.servicesProvided2,
        })}
        {...(frame2172Props.servicesProvided3 && {
          servicesProvided3: frame2172Props.servicesProvided3,
        })}
      />
    </div>
  );
}

export default Frame221;
