import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CookiesBar.css";

function CookiesBar({ visible, acceptCookies, declineCookies }) {
  const [showBanner, setShowBanner] = useState(false);
  const [cookieConsent, setCookieConsent] = useState(null);

  useEffect(() => {
    setShowBanner(visible);
  }, [visible]);

  return (
    showBanner && (
      <div className="cookie-consent-banner">
        <p>This website uses cookies to improve your experience.</p>
        <button className="accept" onClick={acceptCookies}>
          Accept
        </button>
        <button className="decline" onClick={declineCookies}>
          Decline
        </button>
      </div>
    )
  );
}

export default CookiesBar;
