import { Canvas, useLoader } from "@react-three/fiber";
import {
  OrbitControls,
  Center,
  useGLTF,
  Environment,
  AccumulativeShadows,
  RandomizedLight,
  GLTF,
  Preload,
  AdaptiveDpr,
} from "@react-three/drei";
import { useRef, Suspense, useState } from "react";

export default function MacBookTest({ position, fov }) {
  return (
    <Canvas
      camera={{ position, fov }}
      eventPrefix="client"
      eventSource={document.getElementById("root")}
      className="macbook-canvas"
      performance={{ min: 0.1, max: 1 }}
    >
      <Suspense fallback={null}>
        <AdaptiveDpr pixelated />
        <ambientLight
          intensity={0.5}
          castShadow={false}
          shadow={false}
          receiveShadow={false}
        ></ambientLight>
        <Environment files="./img/reflection.hdr" />
        <Center>
          <MacBook scale={[1, 1, 1]} />
        </Center>
        <Preload all />
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          autoRotate={true}
          autoRotateSpeed={3}
        />
      </Suspense>
    </Canvas>
  );
}

export function MacBook(props) {
  // const videoUrl = new URL("static/videos/marketingvideo.mp4", import.meta.url);
  const videoUrl =
    "https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/videos/marketingvideo.mp4";
  const [video] = useState(() => {
    const vid = document.createElement("video");
    vid.src = videoUrl;
    vid.crossOrigin = "Anonymous";
    vid.loop = true;
    vid.muted = true;
    vid.play();
    return vid;
  });
  const { nodes, materials } = useGLTF(
    "./models/compressed/MacbookBlendCorrect-transformed.glb"
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes["13_bottom_black_matte_0"].geometry}
        material={materials["black_matte.007"]}
      />
      <mesh
        geometry={nodes["13_bottom_Chrome_matte_0"].geometry}
        material={materials["Chrome_matte.007"]}
      />
      <mesh
        geometry={nodes["13_bottom_Gray_Aluminium_0"].geometry}
        material={materials["Gray_Aluminium.007"]}
      />
      <mesh
        geometry={nodes["13_keyboard_botton_black_shiny_0"].geometry}
        material={materials["black_shiny.007"]}
      />
      <mesh
        geometry={nodes["13_keyboard_botton_Macbook_Keyboard_0"].geometry}
        material={materials["Macbook_Keyboard.007"]}
      />
      <mesh
        geometry={nodes["13_keyboard_botton_Touch_Bar_0"].geometry}
        material={materials["Touch_Bar.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_Aluminium_no_bump_0"].geometry}
        material={materials["Aluminium_no_bump.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_black_0"].geometry}
        material={materials["black.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_black_matte_0"].geometry}
        material={materials["black_matte.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_Chrome_matte_0"].geometry}
        material={materials["Chrome_matte.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_Gray_Aluminium_0"].geometry}
        material={materials["Gray_Aluminium.007"]}
      />
      <mesh
        geometry={nodes["13_main_cover_speaker_0"].geometry}
        material={materials["speaker.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_Apple_Logo_0"].geometry}
        material={materials["Apple_Logo.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_black_0"].geometry}
        material={materials["black.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_black_matte_0"].geometry}
        material={materials["black_matte.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_camera_static_0"].geometry}
        material={materials["camera_static.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_Crystal_0"].geometry}
        material={materials["Crystal.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_Front_Macbook_Text_0"].geometry}
        material={materials["Front_Macbook_Text.007"]}
      />
      <mesh
        geometry={nodes["13_top_cover_Glass_0"].geometry}
        material={materials["Glass.007"]}
      />
      <mesh geometry={nodes["13_top_cover_Screen_0"].geometry}>
        <meshStandardMaterial>
          <videoTexture attach="map" args={[video]} />
        </meshStandardMaterial>
      </mesh>
    </group>
  );
}

useGLTF.preload("./models/compressed/MacbookBlendCorrect-transformed.glb");
