import { useState, useEffect, forwardRef, useRef } from "react";
import "./ServiceSectionAnimatedDiv.css";
import CanvasTest from "../../utils/Homecheftest_4";
import spin from "../../static/img/spin.png";
import MacBookTest from "../../utils/MacbookBlendCorrect";
import MacBookTestMobile from "../../utils/MacbookMarketingMobile";
import MacBookWebDevelopment from "../../utils/MacBookWebDevelopment";
import EnhancedTextComponent from "../AnimatedServiceTextHelper/AnimatedServiceTextHelper";

function ServiceSectionAnimatedDiv({ currentSection, width }) {
  const [position, setPosition] = useState([0, 1, 80]);
  const [container, setContainer] = useState();
  const [isMobile, setIsMobile] = useState(false);
  // const { height, width } = useWindowDimensions();
  const textRef = useRef(null);
  const web = {
    title: "Web Development",
    text: "Whether you want to revamp your current online presence or stand out from the competition, our team of skilled developers and designers uses modern technology to create visually stunning, user-friendly websites that are tailored to your business's unique vibe and goals. Say goodbye to generic websites and let us help you create a digital experience that truly stands out.",
    className: "spinContainer",
    animation: <MacBookWebDevelopment position={position} fov={30} />,
    bulletPoints: [
      "Custom Development",
      "Webflow",
      "Framer",
      "Shopify",
      "WordPress",
    ],
  };
  const mobile = {
    title: "Mobile Development",
    className: "spinContainer",
    text: "Crafting a mobile application that truly stands out requires a delicate balance of engineering expertise, creativity, and a deep understanding of the intricacies of tiny screens. With our team of skilled developers at the helm, we bring these elements together to develop a mobile app that thrives in the hands of your users. We strive to create an app that is not only fast and feature-rich but also aligns with your goals and vision.",
    animation: <CanvasTest />,
    bulletPoints: ["iOS ", "Android", "Cross-Platform Apps"],
  };
  const uxui = {
    title: "UI/UX Design",
    text: "We understand that great design is more than just pretty visuals. That's why our team focuses on creating digital experiences that are both beautiful and functional. By integrating design thinking and user research, we craft tailored solutions that achieve your objectives and enchant your audience. Whether you need a new website or app design or want to improve your existing one, we're here to help!",
    className: "hide-spin",
    animation: (
      <img
        src="https://alphaencoded.s3.us-west-2.amazonaws.com/static/static/img/design.png"
        className="design"
      />
    ),
    bulletPoints: [
      "User Experience Design",
      "User Interface Design",
      "Wireframing & Prototyping",
      "Usability Testing",
    ],
  };
  const marketing = {
    title: "Branding",
    text: "Transform your brand with our expert design services. We specialize in creating unique logos, comprehensive branding strategies, and tailored template designs that capture the essence of your business. Strengthen your presence and connect with your audience through a distinctive and cohesive brand identity.",
    className: "spinContainer",
    animation: <MacBookTest position={position} fov={30} />,
    bulletPoints: ["Logo Creation", "Branding", "Template Designs"],
  };
  const marketingMobile = {
    title: "Branding",
    text: "Transform your brand with our expert design services. We specialize in creating unique logos, comprehensive branding strategies, and tailored template designs that capture the essence of your business. Strengthen your presence and connect with your audience through a distinctive and cohesive brand identity.",
    className: "spinContainer",
    animation: <MacBookTestMobile position={position} fov={30} />,
    bulletPoints: ["Logo Creation", "Branding", "Template Designs"],
  };
  const [currentItem, setCurrentItem] = useState(web);

  useEffect(() => {
    if (width <= 805) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    if (currentItem.title !== "Mobile Development") {
      if (width <= 500) {
        setContainer({
          width: "100%",
          height: "330px",
          display: "flex",
          position: "relative",
        });
      } else if (width <= 1000 && width > 805) {
        setContainer({
          width: "100%",
          height: "405px",
          display: "flex",
          position: "relative",
        });
      } else if (width <= 804 && width > 450) {
        setContainer({
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
        });
      } else {
        setContainer({
          width: "100%",
          height: "100%",
          display: "flex",
          position: "relative",
        });
      }
    } else {
      setContainer({
        width: "100%",
        height: "100%",
        display: "flex",
        position: "relative",
      });
    }
  }, [width, currentItem]);

  useEffect(() => {
    if (currentSection === "Web") setCurrentItem(web);
    if (currentSection === "Mobile") setCurrentItem(mobile);

    if (currentSection === "Design") setCurrentItem(uxui);
    if (currentSection === "Marketing" && width <= 700)
      setCurrentItem(marketingMobile);
    if (currentSection === "Marketing" && width > 700)
      setCurrentItem(marketing);
  }, [currentSection]);

  return (
    <div id={currentItem.id} className="serviceSectionDiv axl3d ">
      {isMobile ? (
        <>
          <div className="rightSection">
            <div className={currentItem.className}>
              <img src={spin} className="spin" />
            </div>
            <div style={container}>{currentItem.animation}</div>
          </div>
          <div className="leftSection">
            <h2 className="sectionHeader switzer-medium-white-32px">
              {currentItem.title}
            </h2>
            <p className="sectionText spacegrotesk-normal-silver-20px">
              {currentItem.text}
            </p>
            <ul className="bullet-div">
              {currentItem.bulletPoints.map((point, index) => (
                <li key={`${currentItem.title}-${index}`}>
                  <EnhancedTextComponent
                    ref={textRef}
                    text={point}
                    key={`${currentItem.title}-${index}`}
                  />
                </li>
              ))}
            </ul>
          </div>
        </>
      ) : (
        <>
          <div className="leftSection">
            <h2 className="sectionHeader switzer-medium-white-32px">
              {currentItem.title}
            </h2>
            <p className="sectionText spacegrotesk-normal-silver-20px">
              {currentItem.text}
            </p>
            <ul className="bullet-div">
              {currentItem.bulletPoints.map((point, index) => (
                <li key={`${currentItem.title}-${index}`}>
                  <EnhancedTextComponent
                    ref={textRef}
                    text={point}
                    key={`${currentItem.title}-${index}`}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className="rightSection">
            <div className={currentItem.className}>
              <img src={spin} className="spin" />
            </div>
            <div style={container}>{currentItem.animation}</div>
          </div>
        </>
      )}
    </div>
  );
}

export default ServiceSectionAnimatedDiv;
